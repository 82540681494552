﻿@import "_config";

#designatedSites{
    position: relative;

    ul.results{
        list-style-type: none;
        clear: both;
        border-top: 1px solid @darkGrey;
        padding-top: 30px;
        margin-top: 30px;
        margin-left: 0;
    }
}

#designatedSitesSearchForm {
    clear: both;

    h3 {
        margin-bottom: 20px;
    }

    label {
        display: block;
    }

    input[type="button"] {
        border: none;
        border-radius: 3px;
        color: white;
        cursor: pointer;
        background-color: @midGrey;

        &:hover {
            background-color: darken(@midGrey, 5%);
        }
    }

    input, select {
        width: 100%;
        margin-bottom: 30px;
        border: 1px solid @darkGrey;
    }

    .column:nth-child(1n) {
        padding-left: 0;
    }

    .column:last-child {
        padding-right: 0;
    }
}

.designatedSites--NoMarker {
    list-style : none;
}

.designatedSites--TypeHeader {
    margin-bottom: 5px;
}

.designatedSites--DocumentList {
    margin-top: 10px;
    margin-bottom: 10px;
}

input, select {
    margin-top: 3px;
}