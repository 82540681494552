﻿@import "_config";
@import "_button";

.feedbackSection {
    padding-top: 30px;
    padding-bottom: 30px;

    @media @onlyMobile {
        padding-right: 125px !important; // An approximate value to ensure text does not run into the "top" / "i'r brig" button
    }
}

#main-content {
    display: inline-block;
    width: 100%;
    word-break: break-word;

    .content {
        margin-top: 30px;
        color: @bodyTextColour;

        #contentNavigation {
            margin-bottom: 30px;

            &.closed {
                overflow: hidden;

                h2 {
                    &:before {
                        -webkit-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }
                }
            }

            h2 {
                font-size: 18px;
                font-weight: bold;

                @media @onlyMobile {
                    &:before {
                        position: absolute;
                        content: '';
                        display: block;
                        width: 18px;
                        height: 18px;
                        background-image: url(/images/caret-up-black.svg);
                        background-repeat: no-repeat;
                        background-size: 18px 18px;
                        background-position: 100%;
                        right: 20px;
                        top: 16.5px;
                        -webkit-transform-origin: center center;
                        -ms-transform-origin: center center;
                        transform-origin: center center;
                        -webkit-transition: -webkit-transform 225ms ease-out;
                        transition: -webkit-transform 225ms ease-out;
                        transition: transform 225ms ease-out;
                        transition: transform 225ms ease-out,-webkit-transform 225ms ease-out;
                    }
                }
            }

            .contentNavigationColumns {
                margin: 0;
                counter-reset: list-number 0;

                .column {
                    padding-left: 0;
                }

                .contentNavigationItem {
                    color: @aqua;
                    border-bottom: 2px solid @lightGrey;
                    width: 100%;
                    list-style-position: inside;
                    padding: 15px 0;
                    counter-increment: list-number;

                    &.active {
                        border-bottom: 2px solid @darkGrey;
                    }

                    &:hover {
                        border-bottom: 2px solid @lightBlue;
                    }

                    a {
                        font-weight: normal;

                        &:hover {
                            text-decoration: none;
                        }

                        &:before {
                            content: counter(list-number) ". ";
                        }
                    }
                }
            }
        }

        .infoBox {
            background: url('/images/info-icon.svg') no-repeat;
            background-position: top 15px left 20px;
            background-size: 30px;
            padding: 20px;
            padding-left: 65px;
            border: 2px solid @darkGrey;
            margin-bottom: 30px;

            p {
                margin-bottom: 0px;
                line-height: 16px;
            }
        }

        h2 {
            font-weight: bold;
            font-size: 30px;

            span {
                font-weight: bold;
                font-size: 30px;
            }

            strong {
                font-weight: bold;
            }
        }

        h2, h3 {
            margin: 15px 0;
            font-family: @bodyFontFamily;
            display: block;
        }

        .fullWidthImage img {
            width: 100% !important;
            max-width: 615px !important;
            height: auto !important;
            display: block;

            @media @desktop {
                width: 615px !important;
                max-width: 615px !important;
            }
        }

        .thumbnailImage img {
            width: 100% !important;
            max-width: 615px !important;
            height: auto !important;
            display: block;

            @media @tablet {
                width: 25% !important;
                max-width: 153px !important;
                float: left;
                margin-right: 10px;
                margin-top: 5px;
            }

            @media @desktop {
                width: 153px !important;
                max-width: 153px !important;
            }
        }

        img {
            max-width: 100%;
        }

        .MapBtn {
            margin-bottom: 20px;
            width: 100%;
            display: block;

            @media @tablet {
                display: none;
            }
        }

        .MapContent {
            display: none;

            @media @tablet {
                display: block;
            }
        }

        iframe {
            width: 100% !important;
        }

        .content-quote {
            background-color: @lightGrey;
            border-radius: 2px;
            display: block;
            position: relative;
            padding: 0.5em 1em 0.5em 3.5em;
            margin-bottom: -30px;

            &.quote-icon {
                &:before {
                    font-family: 'NewWebsite';
                    display: inline-block;
                    font-size: inherit;
                    font-weight: normal;
                    font-style: normal;
                    line-height: 1;
                    text-rendering: auto;
                    content: "\f01e";
                    font-size: 60px;
                    color: white;
                    display: block;
                    top: 0;
                    left: 10px;
                    position: absolute;
                }
            }

            &.last-quote {
                margin-bottom: 30px;
            }
        }

        blockquote {
            background-color: @lightGrey;
            border-radius: 2px;
            display: block;
            position: relative;
            padding: 0.5em 1em 0.5em 3.5em;

            .content-quote {
                background-color: none;
                margin-bottom: 0px;
                padding: 0px;
            }

            &.quote-icon {
                &:before {
                    font-family: 'NewWebsite';
                    display: inline-block;
                    font-size: inherit;
                    font-weight: normal;
                    font-style: normal;
                    line-height: 1;
                    text-rendering: auto;
                    content: "\f01e";
                    font-size: 60px;
                    color: white;
                    display: block;
                    top: 0;
                    left: 10px;
                    position: absolute;
                    z-index: 2;
                }
            }

            &.last-quote {
                margin-bottom: 30px;
            }
        }

        .contentSection {
            p, ul, ol, img {
                max-width: 615px;
            }
        }

        p {
            margin-bottom: 20px;
            line-height: 24px;

            &.bold {
                font-weight: bold;
            }
        }

        ul, ol {
            margin-bottom: 20px;

            li {
                margin-bottom: 5px;
            }
        }

        table {
            width: 100%;
            border-collapse: collapse;
            word-break: normal;

            th {
                background-color: @lightGrey;
                font-weight: bold;
            }

            td, th {
                border: 1px solid @lightGrey;
            }
        }

        .contentPaginationButtons {
            display: none;
            clear: both;

            .prevContentSection, .nextContentSection {
                padding: 10px 20px;
                width: 100%;
                margin: 15px 0;

                @media @tablet {
                    width: auto;
                }
            }

            .prevContentSection {
                float: left;
                text-align: left;
            }

            .nextContentSection {
                float: right;
                text-align: right;
            }
        }

        .sitemap {
            .sitemap-collection {
                ul {
                    margin-bottom: 0;
                }
            }
        }
    }

    em {
        font-style: italic;
    }

    &.recreation {
        .contentSection {
            p, ul, ol, img, h1, h2, h3, h4, h5 {
                max-width: 615px;
            }
        }
    }

    &.fullScreeniFrame {
        iframe {
            width: 100%;
            min-height: 980px;

            @media screen and (min-width: 575px) {
                min-height: 1230px;
            }

            @media screen and (min-width: 1016px) {
                min-height: 700px;
            }
        }
    }

    &.fullScreeniPowerBiFrame {
        iframe {
            width: 100%;
            height: 100%;
        }
    }


    .iFrameLS {

        @media only screen and (max-width: 450px) {
            display: none;
        }

        @media only screen and (min-width: 450px) {
            min-height: 400px;
        }

        @media only screen and (min-width: 540px) {
            min-height: 425px;
        }

        @media only screen and (min-width: 640px) {
            min-height: 475px;
        }

        @media only screen and (min-width: 740px) {
            min-height: 520px;
        }

        @media only screen and (min-width: 840px) {
            min-height: 575px;
        }

        @media only screen and (min-width: 920px) {
            min-height: 590px;
        }

        @media screen and (min-width: 1016px) {
            min-height: 700px;
        }
    }

    .iFramePT {

        @media only screen and (min-width: 450px) {
            display: none;
        }

        @media only screen and (min-width: 200px) and (max-width: 320px) {
            min-width: 600px;
            border: 0;
            -ms-transform: scale(0.53);
            -moz-transform: scale(0.53);
            -o-transform: scale(0.53);
            -webkit-transform: scale(0.53);
            transform: scale(0.53);
            -ms-transform-origin: 0 0;
            -moz-transform-origin: 0 0;
            -o-transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
        }

        @media only screen and (min-width: 320px) and (max-width: 375px) {
            min-width: 620px;
            border: 0;
            -ms-transform: scale(0.52);
            -moz-transform: scale(0.52);
            -o-transform: scale(0.52);
            -webkit-transform: scale(0.52);
            transform: scale(0.52);
            -ms-transform-origin: 0 0;
            -moz-transform-origin: 0 0;
            -o-transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
        }

        @media only screen and (min-width: 375px) and (max-width: 400px) {
            min-width: 630px;
            border: 0;
            -ms-transform: scale(0.6);
            -moz-transform: scale(0.6);
            -o-transform: scale(0.6);
            -webkit-transform: scale(0.6);
            transform: scale(0.6);
            -ms-transform-origin: 0 0;
            -moz-transform-origin: 0 0;
            -o-transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
        }

        @media only screen and (min-width: 400px) and (max-width: 420px) {
            min-width: 690px;
            border: 0;
            -ms-transform: scale(0.6);
            -moz-transform: scale(0.6);
            -o-transform: scale(0.6);
            -webkit-transform: scale(0.6);
            transform: scale(0.6);
            -ms-transform-origin: 0 0;
            -moz-transform-origin: 0 0;
            -o-transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
        }

        @media only screen and (min-width: 420px) and (max-width: 450px) {
            min-width: 600px;
            border: 0;
            -ms-transform: scale(0.60);
            -moz-transform: scale(0.60);
            -o-transform: scale(0.60);
            -webkit-transform: scale(0.60);
            transform: scale(0.60);
            -ms-transform-origin: 0 0;
            -moz-transform-origin: 0 0;
            -o-transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
        }
    }



    .pwrBiContainer {
        width: 100%;
        height: 1492px;
        padding: 0;
        overflow: hidden;
        margin-bottom: -570px;

        @media (min-width: 450px) {
            display: none;
        }

        @media only screen and (max-width: 320px) {
            margin-bottom: -670px;
        }
    }

    .rhs-sidebar {
        border: 1px solid @midGrey;
    }

    .sidebar {
        background-color: @lightGrey;
        margin-top: 30px;

        &:after {
            display: table;
            content: '';
            clear: both;
        }

        .alsoInThisSection {
            display: inline-block;
        }

        div.alsoInThisSection, div.elseWhereOnTheSiteLinks, div.otherWebsiteLinks {
            padding: 15px;
            clear: both;

            h2 {
                margin-bottom: 15px;
                font-size: 24px;
                font-weight: bold;
                padding: unset;
                font-family: inherit;
            }
        }

        .sidebarExploreMore {
            padding-top: 15px;
            font-size: 21px;
            font-weight: bold;
            padding-left: 15px;
        }

        h3 {
            margin-bottom: 15px;
        }

        a {
            font-weight: normal;
            float: left;
            clear: left;
            margin-bottom: 15px;

            &.bold {
                font-weight: bold;
            }

            i {
                margin-left: 5px;
                font-size: 12px;
            }
        }
    }

    h2.groupHeading {
        font-weight: bold;
        padding-top: 10px;
    }
}

    #documentDownloads {
        display: inline-block;
        border: 2px solid @darkGrey;
        margin: 30px 0;
        padding: 0 20px;
        width: 100%;

        .column {
            padding: 20px 0;

            h2 {
                font-family: @bodyFontFamily;
                margin: 0 0 15px 0;
                font-size: 24px;
                line-height: 28px;
                font-weight: bold;
            }

            .document {
                float: left;
                min-height: 60px;
                margin-bottom: 20px;

                @media @tablet {
                    width: 50%;
                }

                span {
                    float: left;
                    width: 80%;
                    margin-left: 10px;
                    font-weight: normal;
                    color: @darkGrey;

                    a {
                        margin-top: 5px;
                        font-weight: normal;
                    }

                    span.bold {
                        float: none;
                        margin-left: 0;
                        font-weight: 700;
                    }
                }

                i {
                    color: @aqua;
                    float: left;
                }

                &:nth-child(even) {
                    clear: left;
                }
            }
        }
    }


.overview {
    em {
        font-style: italic;
    }
}
    .separator {
        height: 4px;
        width: 100%;
        background-color: @lightGrey;
        display: block;
    }

    .exploreMore {
        padding: 30px 0 0 0;

        h2 {
            margin-bottom: 15px;

            &:first-of-type {
                font-family: @headingFontFamily;
                font-size: 21px;
                font-weight: normal;
                padding-left: 15px;
            }
        }

        .column {
            margin-bottom: 15px;

            h3 {
                margin-bottom: 15px;
                font-size: 24px;
                font-weight: bold;
                font-family: @bodyFontFamily;
                padding-left: 0;
            }
        }
        /*@media @tablet {
        .column:nth-child(1n) {
            padding-left: 0;
        }

        .column:last-child {
            padding-right: 0;
        }
    }*/
        .elseWhereOnTheSiteLinks, .newsletterSignUpBox, .alsoInThisSection, .otherWebsiteLinks {
            background-color: @lightGrey;
            border: 1px solid @midGrey;
            min-height: 180px;
            display: inline-block;
            width: 100%;
            padding: 15px;

            a {
                font-weight: normal;
                display: block;
                margin-bottom: 5px;

                &.bold {
                    font-weight: bold;
                }

                .fa {
                    margin-left: 5px;
                }
            }

            input {
                margin: 5px 0 0 0 !important;
                width: 100%;
            }
        }

        .newsletterSignUpBox {
            padding-left: 4px;
            padding-right: 4px;

            @media @tablet {
                padding-left: 0;
                padding-right: 0;
            }

            .column {
                margin-bottom: 0;
            }
        }

        .alsoInThisSection {
            padding: 15px 8px;

            @media @tablet {
                padding: 15px;
            }
        }
    }

    .disclaimerBanner {
        display: none;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 2;

        .text {
            padding: 60px 30px;
            background-color: white;
            border: 2px solid @lightGreen;
            border-bottom: none;
            box-shadow: 0px -1px 9px 0px rgba(83, 83, 83, 0.4);
            float: left;

            .button {
                padding: 15px 30px;
                float: left;
                margin-top: 30px;
            }

            input {
                clear: left;
                height: 18px;
                width: 18px;
                margin: 0;
                margin-right: 5px;
                margin-top: 15px;
                float: left;
            }

            label {
                font-size: 14px;
                color: @darkGrey;
                float: left;
                margin-top: 15px;
            }
        }
    }

    #TableContainer {
        margin-bottom: 10px;

        table > tbody > tr:first-child {
            background-color: white !important;

            td {
                font-weight: normal !important;
            }
        }

        #searchTerm {
            line-height: normal;
            margin-right: 10px;
        }

        div.dataTables_length {
            padding-left: 2em;
        }

        div.dataTables_length,
        div.dataTables_filter {
            padding-top: 0.55em;
        }

        .dataTables_wrapper .top .dataTables_paginate {
            margin-bottom: 5px;
        }

        .search-term, .search-field {
            display: inline-block;
        }

        .search-field {
            margin-right: 10px;
        }

        @media @onlyMobile {
            .top .dataTables_paginate {
                display: none;
            }

            .top .dataTables_length {
                margin-bottom: 5px;
            }

            .search-term, .search-field {
                display: block;

                label, input, select {
                    display: block;
                }
            }

            .search-field {
                margin-bottom: 5px;
            }
        }
    }

    .table-document i {
        color: @aqua;
        margin-right: 3px;
    }

    @media @onlyMobile {
        .table-document {
            display: none;
        }
    }

    .validation-error {
        color: red;
    }

    table.dataTable {
        width: 100% !important;
    }

    table.dataTable tbody tr:not(.parent) td.dtr-control:before {
        background-color: @lightGreen !important;
    }

