﻿@import "_config";

#cookieBanner {
    display: none;
    background-color: @darkGrey;
    color: white;
    display: block;
    width: 100%;
    padding: 20px 0;
    border-bottom: 4px solid @lightGreen;

    .constrainingContainer {
        position: relative;

        .heading {
            font-size: 28px;
            font-weight: normal;
            color: white;
            margin-bottom: 0;
        }

        p {
            font-size: 16px;
            color: white;
            margin-top: 10px;
        }

        a {
            color: white;
            text-decoration: underline;
            font-weight: normal;

            &.button {
                margin: 0 auto;
                font-weight: bold;
                font-size: 25px;
                padding: 15px 60px 15px 100px;
                text-decoration: none;
                position: relative;
                margin-top: 10px;
                display: inline-block;

                @media @desktop {
                    margin-top: 5px;
                }

                .fa {
                    font-size: 35px;
                    float: left;
                    position: absolute;
                    left: 15px;
                    top: 13px;
                    color: @darkGreen;
                }
            }
        }

        @media @notDesktop {
            .buttonColumn {
                clear: left;
            }
        }
    }
}
