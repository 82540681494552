﻿@import "_config";

.placesToVisit {
    .overview {
        padding-top: 30px;
        padding-bottom: 30px;

        p {
            font-size: 18px;
            max-width: 800px;
        }
    }

    .featuredPlacesHeading {
        padding-top: 30px;
        padding-bottom: 15px;
        margin-bottom: 0;
    }

    .featuredPlace {
        margin-bottom: 30px;
    }

    .featuredPlacesContainer {
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .featuredPlace .image {
        width: 100%;
        align-self: start;
    }

    .featuredPlace .imageContainer {
        margin-bottom: 15px;
        display: flex;
    }

    .featuredPlace .name {
        font-size: 30px;
        margin-bottom: 15px;
        color: @aquaOnLightGrey;
        font-weight: bold;
    }

    .featuredPlace .description {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .featuredPlace .childLink,
    .featuredPlace .seeMoreLink {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 2px solid @lightGrey;
        font-weight: normal;
        color: @aquaOnLightGrey;

        &:hover {
            border-bottom-color: @lightBlue;
            text-decoration: none;
        }
    }

    .featuredPlace .seeMoreLink {
        color: @lightGreen;
        font-weight: bold;
    }

    .whatsOn, .exploreMoreSection {
        margin-top: 30px;

        .featured {
            margin-bottom: 8px;
            color: white;
        }
    }

    .whatsOn {
        margin-bottom: 30px;
    }

    .exploreMoreSection {
        margin-bottom: -8px;
    }

    @media @tablet {
        .overview {
            padding-top: 60px;
            padding-bottom: 30px;
        }

        .featuredPlacesHeading {
            padding-top: 30px;
            padding-bottom: 15px;
        }

        .featuredPlace.odd {
            clear: left;
        }

        .whatsOn, .exploreMoreSection {
            .featured {
                margin-bottom: 30px;
            }

            & > .columnTablet6:nth-child(even) {
                clear: left;
            }
        }

        .exploreMoreSection {
            margin-bottom: -30px;
        }
    }

    @media @desktop {
        .whatsOn, .exploreMoreSection {
            & > .columnDesktop3:nth-child(even) {
                clear: none;
            }
        }
    }
}