﻿@import "_config";

.dates {
    background-color: rgba(0,0,0,0.5);
    position: relative;
    height: 76px;
    padding: 10px 20px;
    padding-top: 15px;
    display: inline-block;
            color: white;

    &:before {
        background-color: @lightBlue;
        position: absolute;
        content: '';
        display: block;
        height: 5px;
        top: 10px;
        left: 0;
        right: 10px;
    }

    .date {
        text-align: center;
        display: inline-block;
        vertical-align: top;
        padding-top: 5px;
        position: relative;
        line-height: 1;
        margin-left: 25px;

        &:first-child {
            margin-left: 0;

            &:before {
                display: none;
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 4px;
            background-color: white;
            left: -20px;
            top: 15px;
        }
    }

    .day, .month {
        font-family: @headingFontFamily;
    }

    .day {
        font-size: 30px;
        margin-bottom: 2px;
    }

    .month {
        font-size: 16px;
    }
}

@media @onlyMobile {
    .dates {
        //left: 8px;
        //top: 8px + 72px - 33px; // padding above image + image height - this element's height
        height: 33px;
        padding-bottom: 0;
        padding-left: 8px;
        padding-top: 7px + 5px + 3px; // space above the blue bar + height of bar + some spacing between the text and bar
        padding-right: 8px;
        font-size: 13px;
        max-width: 127px;
        text-align: right;
        margin-top: -33px;

        &.full {
            width: 100%;
        }

        &:before {
            top: 7px;
            right: 7px;
        }

        .day, .month {
            font-family: @bodyFontFamily;
            font-size: 13px;
            display: inline;
            font-weight: bold;
        }

        .date {
            margin-left: 0;
            padding-top: 0;
            display: inline;
        }

        .date:before {
            content: '-';
            background-color: transparent;
            width: auto;
            height: auto;
            display: inline;
            background-color: transparent;
            position: static;
        }

        .date:first-child::before {
            display: none;
        }
    }
}
