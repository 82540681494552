﻿@import "_config";

.homeHeroContentContainer {
    color: white;
    display: table;
    width: 100%;
}

.homeHeroText {
    display: table-row;
}

.homeHeroSearch {
    width: 100%;
    height: auto;
    vertical-align: middle;
    display: table-row;

    .searchBoxContainer {
        background-color: fade(@transparentDarkLayer, 25%);
        padding: 15px 8px;
        margin: 80px -8px -15px -8px; // [push up text 50px], [pull left padding on .constrainingContainer], [pull bottom padding on .hero], [pull right padding on .constrainingContainer] respectively
        display: block;
    }

    label{
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .searchBox {
        height: 53px;
        line-height: 1;
        font-size: 16px;
        padding: 0 8px;
        padding-right: (53px + 53px);
        border: none;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }

    .searchBoxIcon {
        height: 53px;
        width: 53px;
        background-size: 24px 24px;
        top: 15px;
        right: 8px;
    }

    .searchBoxClear {
        height: 53px;
        width: 53px;
        top: 15px;
        right: 53px + 8px;
    }
}

.homeHeroTitle {
    margin-top: 10px;
    color: white;
    font-size: 27px;
    font-weight: bold;
    line-height: (62 / 59); // 62px in psd, 59px desktop font size
    margin-bottom: 15px;
}

.homeHeroSubtitle {
    font-size: 16px;
    color: white;
}

.home{
    width: 75%;
    display: inline-block;
    padding-top: 30px;
    @media @tablet{
        width: 100%;
        display: block;
    }
}

@media @tablet {
    .homeHeroTitle {
        font-size: 59px;
        margin-top: 0;
        margin-bottom: 14px;
    }

    .homeHeroSubtitle {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .homeHeroContentContainer {
        padding: 50px 0;
        padding-bottom: 0;
    }

    .homeHeroSearch {
        .searchBoxContainer {
            margin-left: -15px;
            margin-right: -15px;
            padding: 15px;
        }

        .searchBoxIcon {
            right: 15px;
        }

        .searchBoxClear {
            right: 15px + 53px;
        }
    }
}

@media @desktop {
    .homeHeroTitle {
        font-size: 59px;

    }

    .homeHeroContentContainer {
        padding: 95px 0;
        padding-bottom: 105px;
    }

    .homeHeroText {
        display: table-cell;
        padding-right: 30px;
    }

    .homeHeroSearch {
        display: table-cell;

        .searchBox {
            height: 70px;
            padding: 0 20px;
            padding-right: (70px + 70px);
            font-size: 18px;
            border-radius: 3px 6px 6px 3px;
        }

        .searchBoxContainer {
            margin: 0; //reset neg. margins
            padding: 30px;
        }
        
        .searchBoxIcon {
            top: 30px;
            right: 30px;
            height: 70px;
            width: 70px;
            background-size: 30px 30px;
            border-radius: 0 6px 6px 0;
        }

        .searchBoxClear {
            top: 30px;
            right: 30px + 70px;
            height: 70px;
            line-height: 70px;
        }
    }
}