﻿@import "_config";

.pageHeading {
    color: white;
    width: 100%;

    .breadcrumbs {
        display: inline-block;
        background-color: black;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        margin-top: 10px;
        border-radius: 2px;
        padding: 5px 10px;
        line-height: 18px;

        a {
            color: white;
            font-weight: normal;

            &:hover {
                text-decoration: underline;
            }
        }

        .divider {
            font-size: 13px;
            font-weight: 100;
            line-height: 18px;
        }
    }

    h1 {
        margin-top: 10px;
        font-weight: bold;
    }

    h1, p {
        padding-bottom: 30px;
        color: white;
    }

    @media @tablet {
        h1 {
            margin-top: 25px;
        }
    }

    .floodWarningsStatus {
        margin-top: 15px;
        margin-bottom: 15px;
        background-color: transparent;

        .floodWarningsStatusCell {
            background-color: white;
        }

        .floodWarningsStatusHeading a {
            color: @aqua;
        }

        @media @notDesktop {
            background-color: white;

            .floodWarningsStatusItem {
                background-color: @lightGrey;
            }
        }
    }

    &.lightGrey {
        h1, p {
            color: @darkGrey;
        }

        .breadcrumbs {
            color: @darkGrey;

            a {
                color: @aquaOnLightGrey;
            }
        }
    }

    @media @onlyMobile {
        margin-bottom: 15px;
    }
}