﻿@import "_config";

#main-content.landing {
    .sectionHeading {
        h2 {
            padding: 15px 0;
            margin-bottom: 0;
            font-weight: bold;

            @media @tablet {
                padding-top: 30px;
                padding-bottom: 0;
            }

            @media @desktop {
                padding-bottom: 30px;
            }
        }
    }

    .column {
        &.closed {
            overflow: hidden;

            h3 {
                &:before {
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }

        h3 {
            border-top: 2px solid @lightGrey;
            padding: 15px 0;
            margin-bottom: 0;

            &.expandable {
                @media @onlyMobile {
                    &:before {
                        position: absolute;
                        content: '';
                        display: block;
                        width: 18px;
                        height: 18px;
                        background-image: url(/images/caret-up-black.svg);
                        background-repeat: no-repeat;
                        background-size: 18px 18px;
                        background-position: 100%;
                        right: 8px;
                        top: 23px;
                        -webkit-transform-origin: center center;
                        -ms-transform-origin: center center;
                        transform-origin: center center;
                        -webkit-transition: -webkit-transform 225ms ease-out;
                        transition: -webkit-transform 225ms ease-out;
                        transition: transform 225ms ease-out;
                        transition: transform 225ms ease-out,-webkit-transform 225ms ease-out;
                    }
                }
            }

            @media @tablet {
                margin-top: 0;
                border-top: 0;
                padding-top: 0;
                margin-bottom: 0;
            }
        }
    }
    /* News & Events Landing page */
    .latestArticles {
        clear: both;
        display: inline-block;
        width: 100%;
        padding: 45px 0 30px 0;

        &:nth-child(2n) {
            background-color: @lightGrey;

            .article {
                border-bottom-color: @lightGrey;
            }
        }

        .column {
            min-height: 1px;
        }

        h2 {
            line-height: 1;
            margin-bottom: 15px;
        }

        .leadArticle h3 {
            font-size: 30px;
            line-height: 32 / 30;
            margin-bottom: 15px;
            padding: 0;
            border-top: none;
        }

        h4 a {
            font-size: 24px;
        }

        a {
            color: @aquaOnLightGrey;

            &.viewAll {
                color: @lightGreen;
                float: left;
                clear: left;
            }
        }

        .article ~ a.viewAll {
            margin-top: 30px;
        }

        .imageWrapper {
            position: relative;
            margin-top: 15px;

            img {
                width: 100%;
                display: block;
            }
        }

        @media @tablet {
            .imageWrapper {
                margin: 0;
            }
        }

        .dates {
            color: white;
        }

        .imageWrapper + .dates {
            position: absolute;
            top: auto;
            bottom: 0;
            left: 4px;

            @media @tablet {
                left: 15px;
            }
        }

        p {
            margin-bottom: 15px;
            line-height: 21 / 16;

            &.details {
                margin: 0;
            }
        }

        .article {
            padding: 0 0 15px 0;
            border-bottom: 2px solid @lightGrey;

            &:hover {
                border-bottom: 2px solid @lightBlue;
            }

            @media @desktop {
                &:first-child {
                    padding-top: 0;
                }
            }

            @media @notDesktop {
                &:first-child {
                    margin-top: 15px;
                    border-top: 2px solid @lightGrey;
                }
            }

            a {
                margin: 0;

                &:hover {
                    text-decoration: none;
                }
            }

            h3 {
                font-size: 16px;
                line-height: 21 / 16;
                padding-bottom: 10px;
                border-top: none;
            }

            p.date {
                clear: left;
                margin: 0;
                line-height: 1;
            }

            @media @tablet {
                padding: 14px 0;
            }
        }
    }

    .exploreMore {
        margin-top: 0;

        h2 {
            margin-bottom: 15px;
        }

        h3 {
            margin: 0 0 15px 0;
            font-family: @headingFontFamily;
            font-weight: normal;
            font-size: 18px;

            &:before {
                background-image: none;
            }
        }

        .column {
            @media @tablet {
                padding: 0 15px;
            }

            h3 {
                font-family: @bodyFontFamily;
                font-size: 22px;
                margin: 0 0 20px 0;
                font-weight: bold;
                padding-bottom: 0;
            }
        }

        .alsoInThisSection {
            a {
                font-weight: normal;
                font-size: 16px;
                width: 100%;
                float: left;
                clear: none;
                margin-bottom: 10px;

                @media @tablet {
                    width: 50%;
                }

                &.bold {
                    font-weight: bold;
                }
            }
        }

        .alsoInThisSection, .newsletterSignUpBox {
            min-height: 200px;
        }

        form {
            margin-top: 15px;

            @media @tablet {
                margin-top: 0;
            }
        }
    }
}

#popularAndRelatedContent.landing {
    .constrainingContainer {
        h2 {
            font-size: 21px;
        }

        a {
            font-size: 18px;
        }
    }
}

.landingLinksSection {
    a {
        display: table;
        font-size: 18px;
        margin-bottom: 15px;

        i {
            padding-left: 5px;
            font-weight: bold;
        }
    }

    h2 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    h2:not(:first-child) {
        margin-top: 20px;
    }

    @media @tablet {
        padding-bottom: 30px - 15px;
        padding-top: 30px;
    }
}