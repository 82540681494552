﻿@import "_config";

.siteHeader {
    height: 45px + 52px;
    border-bottom: 1px solid #E4E4E5;
}

.siteHeaderConstrained {
    display: block;
    height: 45px;
    position: relative;
    border-bottom: 2px solid @lightGrey;
    padding: 0 8px;


    @media @tablet {
        padding: 0 15px;
    }

    @media @desktop {
        padding: 0 30px;
    }
}

.siteHeaderItem {
    display: table-cell;
    vertical-align: top;
}

.siteHeaderLogo {
    width: 215px;
    height: 38px;
    margin-left: 10px;
    display: block;
}

.siteHeaderLogoLink {
    display: block;
    position: absolute;
    top: 52px;
    left: 0;

    a {
        display: inline-block;
        vertical-align: top;
        width: auto;
    }
}

.siteHeaderLinks {
    list-style: none;
    float: right;
    //padding-right: 5px;
    font-size: 0; // inline-block spacing fix
    white-space: nowrap; // force this container to hold everything on one line
}

.siteHeaderLink {
    margin-left: 20px;
    line-height: 45px;
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    font-weight: normal;
    color: @darkGrey;
    letter-spacing: -0.025em;

    &:first-child {
        padding-left: 0;
    }

    .button {
        @buttonHeight: 26px;
        line-height: @buttonHeight;
        font-weight: bold;
        padding: 0 10px;
    }
}

.siteHeaderSearchContainer {
    width: 100%;
    vertical-align: middle;
    text-align: center;
    position: relative;
    position: absolute;
    top: 45px + 52px;
    left: 0;
    padding: 10px;
    display: none;
    background-color: @lightGreen;
    label{
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .searchBox {
        padding: 0 10px;
        padding-right: 44px;
        border: none;
    }

    .searchBoxIcon {
        background-image: url('/images/search-icon-green.svg');
        background-color: transparent;
        cursor: pointer;
    }
}

.siteHeaderMobileActions {
    position: absolute;
    height: 54px;
    right: 0;
    top: 43px;
    font-size: 0; //inline-block fix
    .button {
        background-color: transparent;
        height: 100%;
        color: black;
        background-repeat: no-repeat;
        background-size: 26px 26px, 26px 26px;
        background-position: center center, center (54px + 26px);
        width: 46px;
        font-size: 0;
        border-radius: 0;
        transition: background-position 225ms ease-out, background-color 225ms ease-out;

        @media screen and (min-width: 360px) {
            // iPhone 5's resolution is too small for the buttons and logo to fit with adequate spacing
            // for devices that are not the iphone 5, we give the buttons more space
            width: 56px;
        }

        &.active {
            background-position: center (-26px), center center;
        }
    }

    .siteHeaderSearchButton {
        // The reason why the close icon is on top of the original icon is because the browser will preload the close icon
        // background-size sets the  close icon not to be visible
        background-image: url('/images/search-icon-green.svg'), url('/images/close-icon.svg');

        &.active {
            background-color: @lightGreen;
        }
    }

    .siteHeaderMenuButton {
        background-image: url('/images/menu-icon.svg'), url('/images/close-icon.svg');

        &.active {
            background-color: @darkGrey;
        }
    }
}

@media screen and (max-width: (@desktopWidth - 1)) {
    .siteHeader {
        transition: margin-bottom 225ms ease-out;
    }

    .searchVisible .siteHeader {
        margin-bottom: 64px; // when search is visible
        .siteHeaderSearchContainer {
            display: block;
        }
    }
}

@media @tablet {
    .siteHeader {
        height: auto;
    }

    .siteHeaderConstrained {
        border-bottom: none;
        height: auto;
        display: table;
        height: @siteHeaderDesktopHeight;
    }

    .siteHeaderLogo {
        display: block;
        margin: ((@siteHeaderDesktopHeight - @siteHeaderLogoHeight) / 2) 0;
        margin-left: 0;
        width: @siteHeaderLogoWidth;
        height: @siteHeaderLogoHeight;
    }

    .siteHeaderLogoLink {
        position: static;
        top: 0;
        display: table-cell;
        width: 100%;
    }

    .siteHeaderLinks {
        display: table-cell;
        text-align: right;
        float: none;
        white-space: nowrap;
    }

    .siteHeaderLink {
        line-height: @siteHeaderDesktopHeight;
    }

    .siteHeaderMobileActions {
        position: relative;
        top: 0;
        margin-right: -15px;
        white-space: nowrap;
        padding-left: 15px;
        height: @siteHeaderDesktopHeight;

        .button {
            height: @siteHeaderDesktopHeight + 1px;
        }
    }

    .siteHeaderSearchContainer {
        top: 65px;
    }
}

@media @desktop {
    .siteHeader {
        background-color: @lightGrey;
    }

    .siteHeaderConstrained {
        border-bottom: none;
        height: auto;
        display: table;
    }

    .siteHeaderSearchContainer {
        display: table-cell;
        position: relative;
        top: 0;
        background-color: transparent;
        padding-left: 30px;

        .searchBox {
            border: 1px solid #d7d7d8;
            padding: 0 15px;
            padding-right: 44px + 15px;
        }

        .searchBoxIcon {
            background-image: url('/images/search-icon.svg');
            &[aria-disabled=false] {
                background-color: @lightGreen;
                cursor: pointer;
            }

            &[aria-disabled=true] {
                background-color: @midGrey;
                cursor: not-allowed;
            }
        }
    }

    .siteHeaderLink {
        font-size: 15px;
    }

    .siteHeaderLogoLink {
        width: auto;
    }

    .siteHeaderMobileActions {
        display: none;
    }
}