﻿@import "_config";

.banner {
    width: 100%;
    min-height: 115px;
    background-color: @aqua;
    color: white;
    display: block;
    padding: 15px 8px;
    position: relative;
    padding-bottom: 15px + 46px + 60px; // initial padding on the bottom + approx button height + some more padding
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 30px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        pointer-events: none;
    }

    &:hover {
        text-decoration: none;

        .button {
            background-color: darken(@lightGreen, 5%);
        }
    }
}

.banner .button {
    padding-top: 15px;
    padding-bottom: 15px;
    white-space: nowrap;
    font-weight: bold;
    line-height: 1;
    padding-right: 60px;
    background-image: url('/images/right-arrow.svg');
    background-size: 25px 25px;
    background-position: calc(~"100% - 20px") center;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 15px;
    left: 8px;
    right: 8px;
    z-index: 2;
}

.bannerHeading {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 10px;
    color: white;
}

.bannerText {
    position: relative;
    z-index: 1;

    .bannerDescription {
        color: white;
    }
}

@media @tablet {
    .banner {
        min-height: 115px;
        display: table;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 20px;
        padding-bottom: 20px;

        &:before {
            background-image: linear-gradient(to right, rgba(0,0,0, 0.5), rgba(0,0,0,0));
            background-color: transparent;
        }
    }

    .bannerText {
        width: 360px;
        display: table-cell;
        vertical-align: middle;
    }

    .bannerAction {
        display: table-cell;
        vertical-align: middle;
        text-align: right;
    }

    .banner .button {
        display: inline;
        position: relative;
        left: unset;
        right: unset;
        top: unset;
        bottom: unset;
    }
}

@media @desktop {
    .banner {
        &:before {
            background-image: linear-gradient(to right, rgba(0,0,0, 0.2), rgba(255, 255, 255, 0));
        }
    }
}