﻿@import "_config";

.searchResults{
    margin: 0;
    margin-top: 15px;

    @media @tablet {
        margin-top: 30px;
    }

    .searchInfoBox{
        border: 2px solid @lightGrey;
        padding: 15px;
        margin-bottom: 30px;

        span{
            float: left;
            @media @tablet{
                float: none;
            }
        }

        a{
            clear: both;
            display: block;
            @media @tablet{
                float: right;
                clear: none;
            }
        }
    }

    ul.searchResults{
        list-style-type: none;
    
        li{
            margin-bottom: 30px;

            &.featuredResult{
                border: 1px solid @aqua;
                padding: 15px;
            }

            a{
                font-size: 18px;
                margin-bottom: 15px;
            }

            p{
                &.date{
                    float: right;
                    text-align: right;
                    width: 40%;
                    font-size: 14px;

                    @media @tablet{
                        width: 30%;
                    }
                }

            }
        }
    }

    .pageButtonsBox{
        border: 2px solid @lightGrey;
        clear: both;
        padding: 15px;
        margin: 30px 0;
        height: 54px;

        .next{
            float: right;
        }
    }
}