﻿@import "_config";

#wasteCarriers select {
    padding: 2px;
}

#wasteCarriers #searchFieldContainer {
    float: right;
    margin-bottom: 10px;
}

#wasteCarriersTable_filter input[type=search] {
    line-height: 2em;
    margin-bottom: 5px;
}

#wasteCarriersTable > tbody > tr:first-child {
    background-color: white !important;
    td {
        font-weight: normal !important;
    }
}
