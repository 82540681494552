﻿@import "_config";

.button {
    -webkit-appearance: none;
    background-color: @lightGreen;
    background-image: none;
    border: none;
    color: white;
    padding: 5px 15px;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: darken(@lightGreen, 5%);
        text-decoration: none;
    }
}

.button(@height) {
}