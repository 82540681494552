﻿@import "_config";

html {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*, *:before, *:after {
    -moz-box-sizing: inherit;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    font-family: @bodyFontFamily;
    font-size: 16px;
}

p {
    font-size: 16px;
    color: @bodyTextColour;
}

.bold {
    font-weight: bold;
}

ul, ol {
    margin-left: 18px;
}

a {
    text-decoration: none;
    color: @linkColour;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
}

strong {
    font-weight: bold;
}

input {
    font-family: inherit;
}

textarea {
    font-family: inherit;
}

h1 {
    font-size: 48px;
    color: @darkGrey;

    &.stencil {
        font-weight: bold;
        font-family: @headingFontFamily;
    }


    @media @tablet {
        font-size: 60px;
    }
}

.pageHeading h1 {
    font-size: 36px;

    @media @tablet {
        font-size: 48px;
    }
}

h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: @darkGrey;

    &.featuredHeading {
        font-size: 22px;
        font-weight: bold;
    }
}

h3 {
    font-size: 24px;
    font-weight: bold;
    color: @darkGrey;

    @media @tablet {
        line-height: 28px;
    }
}

h4 {
    font-size: 21px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

#fullSiteBackToTop {
    display: block;
    position: absolute;
    right: 0;
    top: -77px;
    background-color: white;
    padding: 10px 45px 10px 15px;
    background-image: url(/images/back-to-top-arrow.svg);
    background-repeat: no-repeat;
    background-size: 16px 10px;
    background-position: calc(100% - 16px) center;
    color: @lightGreen;
    border: 2px solid @lightGreen;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;

    transition: opacity 225ms ease-out 0s, visibility 225ms ease-out 0s;

    &.hide {
        opacity: 0;
        visibility: hidden;
        transition-delay: 0s, 225ms;
    }

    @media @tablet {
        top: -24px;
    }

    span {
        font-weight: bold;
    }

    &:hover {
        cursor: pointer;
    }
}