﻿@import "_config";

#main-content {
    &.news {
        .sidebar {
            background: none;
            padding: 0;
            border-top: 15px solid @lightGrey;

            .tags {
                display: inline-block;
                padding: 15px 0;

                a {
                    font-weight: bold;
                    float: left;
                    clear: none;
                    margin: 0;
                    margin-right: 5px;
                }
            }

            p {
                padding: 5px 0;
                margin: 10px 0;

                &.date {
                    border-top: 1px solid @lightGrey;
                    border-bottom: 1px solid @lightGrey;
                }
            }
        }

        .content {
            .subTitle {
                font-size: 18px;
            }
        }
    }
}
