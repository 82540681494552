﻿@import "_config";

@media @tablet {
    .mobileOnly {
        display: none;
    }
}

@media @desktop {
    .mobileTabletOnly {
        display: none !important;
    }
}

@media screen and (max-width: (@desktopWidth - 1px)) {
    .desktopOnly {
        display: none;
    }
}

@media screen and (max-width: (@tabletWidth - 1px)) {
    .tabletDesktopOnly {
        display: none;
    }
}