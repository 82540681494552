﻿@import "_config";

.mostPopularLinks {
    margin-bottom: 24px;
}

.mostPopularLinkItem {
    margin-top: 20px;
    width: 100%;

    div.linkIcon {
        float: left;
        width: 20%;
    }

    div.linkTitle {
        float: left;
        width: 80%;
    }

    i {
        color: @aqua;
    }

    p {
        color: @bodyTextColour;
        font-size: initial;
    }

    a {
        color: @aquaOnLightGrey;
        font-size: larger;
    }
}

#popularAndRelatedContent {
    padding-top: 15px;
    padding-bottom: 15px;

    @media @tablet {
        padding-top: 38px;
        padding-bottom: 15px;

        h2 {
            margin-bottom: 34px;
            font-weight: bold;
        }
    }

    @media @desktop {
        padding-bottom: 30px;
    }

    h2 {
        line-height: 1;
    }
}

#popularContent {
    padding-right: 4px;

    .column {
        padding-right: 0;
    }
    
    @media @tablet {
        padding-right: 15px;
        
        .columnTablet6:nth-child(odd) {
            clear: left;
        }
    }
}