﻿/*
    This file contains the possible background colour and image combinations for
    sections throughout the NRW website. These are set in the Razor views, but are
    pulled through from the NRW Background Colour Picker data type. Additions should
    be made there, ensuring the naming convention matches with an option below.

    The 'withGraphic' option is added on manually within the Razor views, depending on
    the size of the container in question.
*/

@import "_config";

.backgroundColourSetup {
    background-repeat: no-repeat;
    background-size: 34%; //34% 105%;
    background-position-y: center; //top right;
    background-position-x: 105%;
}

.lightGreen {
    .backgroundColourSetup;
    background-color: @lightGreen;

    @media @tablet {
        &.withGraphic {
            background-image: url('/images/NewsListingGraphic.svg') !important;
        }
    }
}

.darkGreen {
    .backgroundColourSetup;
    background-color: @darkGreen;

    @media @tablet {
        &.withGraphic {
            background-image: url('/images/trees-bg.svg') !important;
        }
    }
}

.lightBlue {
    .backgroundColourSetup;
    background-color: @lightBlue;

    @media @tablet {
        &.withGraphic {
            background-image: url('/images/waves-bg-cropped.svg') !important;
        }
    }
}

.aqua {
    .backgroundColourSetup;
    background-color: @aqua;

    @media @tablet {
        &.withGraphic {
            background-image: url('/images/waves-bg-cropped.svg') !important;
        }
    }
}

.darkGrey {
    .backgroundColourSetup;
    background-color: @darkGrey;

    @media @tablet {
        &.withGraphic {
        }
    }
}

.lightGrey {
    .backgroundColourSetup;
    background-color: @lightGrey;

    @media @tablet {
        &.withGraphic {
        }
    }
}