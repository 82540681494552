﻿@import "_config";

.carousel {
    padding: 0;
    position: relative;
    background-color: @bodyTextColour;

    @media @desktop {
        height: 550px;

        .slickContainer, .slick-list, .slick-track {
            height: 100%;
        }
    }

    .constrainingContainer {
        &:after {
            display: table;
            content: '';
            clear: both;
        }
    }

    .carouselControls {
        display: none;
        float: right;
        margin-bottom: -48px;
        z-index: 50;
        position: relative;
        height: 48px;
        background-color: black;
        background-color: rgba(0,0,0,0.5);
        font-size: 0; // inline-block whitespace gaps fix
        .js & {
            display: block;
        }
    }

    .carouselTextProgress {
        line-height: 1;
        color: white;
        display: inline-block;
        vertical-align: middle;
        min-width: 48px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
    }

    .carouselButton {
        width: 48px;
        height: 48px;
        font-size: 0; // image replacement
        position: relative;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        cursor: pointer;
        border: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        border-radius: 0;

        &:before {
            background-repeat: no-repeat;
            background-size: 16px 16px;
            background-position: center;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            content: '';
            display: block;
        }

        &.carouselPrev:before {
            transform: rotateZ(90deg);
            background-image: url(/images/caret-down.svg);
        }

        &.carouselNext:before {
            transform: rotateZ(-90deg);
            background-image: url(/images/caret-down.svg);
        }

        &.carouselPause:before {
            background-image: url(/images/pause.svg);
            background-size: 24px 24px;
        }

        &.carouselPause.carouselPaused:before {
            background-image: url(/images/play.svg);
        }
    }

    .carouselProgressBars {
        height: 5px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 20;
    }

    .carouselProgressBar {
        background-color: @aqua;
        left: 0;
        top: 0;
        right: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        transition: transform 1s linear;
        transition-duration: inherit;
        transform: scaleX(0);
        transform-origin: left center;
    }

    .carouselProgressBarLeaving {
        transform: scaleX(0) !important;
        transform-origin: right center;
        transition-timing-function: ease-in;
    }

    .carouselDownArrow {
        background-image: url('/images/down-arrow.svg');
        background-repeat: no-repeat;
        background-size: 36px 36px;
        background-position: center center;
        width: 56px;
        height: 56px;
        font-size: 0;
        position: absolute;
        bottom: 10px;
        left: 50%;
        margin-left: -28px;
        display: block;
        z-index: 4;
        animation: bounce 16s infinite;
        animation-timing-function: ease-in-out;
        border-radius: 100%;
        cursor: pointer;

        &:hover {
            background-color: rgba(0,0,0,0.5);
            animation-play-state: paused;
        }
    }

    @keyframes bounce {
        0% {
            transform: translateY(0px);
            animation-timing-function: ease-out;
        }

        6.25% {
            transform: translateY(-15px);
            animation-timing-function: ease-in;
        }

        12.5% {
            transform: translateY(0px);
            animation-timing-function: ease-out;
        }

        18.75% {
            transform: translateY(-15px);
            animation-timing-function: ease-in;
        }

        25% {
            transform: translateY(0px);
            animation-timing-function: ease-out;
        }

        31.25% {
            transform: translateY(-15px);
            animation-timing-function: ease-in;
        }

        37.5% {
            transform: translateY(0px);
            animation-timing-function: ease-out;
        }

        100% {
            transform: translateY(0px);
        }
    }
}

.carouselSlide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    display: none;

    &:first-child, .js & {
        display: block;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: overlay(rgba(0,0,0,0.25), rgba(1,52,59,0.49));
        content: '';
        z-index: 1;
    }

    .carouselSlideImage {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // polyfill
        font-family: "object-fit: cover;";
        z-index: 0;
    }

    & > .constrainingContainer {
        position: relative;
        z-index: 2;
        text-align: center;
        color: white;
        padding-top: 80px;
        padding-bottom: 90px;
    }

    h1 {
        font-size: 30px;
        color: white;
    }

    .button {
        padding: 15px 20px;
        font-size: 16px;
        background-color: black;
        background-color: rgba(0,0,0,0.5);
        display: table;
        margin: 0 auto;
        margin-top: 10px;
        font-weight: normal;
    }
}

@media @tablet {
    .carouselSlide h1 {
        font-size: 48px;
    }

    .carouselSlide .button {
        font-size: 18px;
        margin-top: 30px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: bold;
    }

    .carouselSlide > .constrainingContainer {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .carousel .carouselControls {
        height: 53px;
        margin-bottom: -53px;
    }

    .carousel .carouselButton {
        height: 53px;
    }

    .carousel .carouselDownArrow {
        bottom: 35px;
    }
}

@media @desktop {
    .carouselSlide h1 {
        font-size: 60px;
    }
}