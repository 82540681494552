﻿@import "_config";

.searchBoxContainer {
    position: relative;
}

@searchBoxHeight: (64px - (10px * 2));

.searchBox {
    padding: 0 15px;
    border: 1px solid @lightGrey;
    border-radius: 3px 3px 3px 3px;
    font-size: 16px;
    letter-spacing: -0.01em;
    width: 100%;
    //outline-color: @lightGreen;
    -webkit-appearance: none; // Safari fix
    padding-right: (@searchBoxHeight + @searchBoxHeight + 15px);
    line-height: @searchBoxHeight;
    height: @searchBoxHeight;
    color: @bodyTextColour;

    .js & {
        &::-webkit-search-cancel-button {
            display: none;
            -webkit-appearance: none;
        }

        &::-ms-clear {
            display: none;
        }
    }
}

.searchBoxClear {
    position: absolute;
    right: @searchBoxHeight;
    width: @searchBoxHeight;
    height: @searchBoxHeight;
    font-size: 24px;
    text-align: center;
    line-height: @searchBoxHeight;
    color: @lightGreen;
    top: 0;
    -webkit-appearance: none;
    border: 0;
    padding: 0;
    background-color: transparent;
    display: none;
    cursor: pointer;

    .js .showClear .searchBox:focus ~ &,
    .js .showClear .searchBox:hover ~ &,
    .js .showClear &:hover,
    .js .showClear &:focus,
    .js .showClear &.focused {
       display: block;
    }
}

.searchBoxIcon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    border: none;
    border-radius: 0px 3px 3px 0px;
    -webkit-appearance: none;

    &[aria-disabled=false] {
        background-color: @lightGreen;
        cursor: pointer;
    }

    &[aria-disabled=true] {
        background-color: @midGrey;
        cursor: not-allowed;
    }

    background-image: url('/images/search-icon.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center center;
    font: 0/0 a; // image replacement
    width: @searchBoxHeight;
    height: @searchBoxHeight;
    cursor: pointer;
}
