﻿@import "_config";
@import "_button";

#main-content {
    input {
        font-size: 16px;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: white;
        border: 2px solid @darkGrey;
        color: @darkGrey;
        line-height: 40px;

        &[type="text"] {
            width: 100%;
        }
    }

    input[type="submit"] {
        .button;
        padding: 10px 30px;
        margin-top: 30px;
    }

    input[type="button"] {
        padding: 10px 30px;
        margin-top: 30px;
    }

    .ieLabels {
        display: none;
    }
    /* User-generated forms */
    .umbraco-forms-page {
        legend {
            clip: rect(1px, 1px, 1px, 1px);
            clip-path: inset(50%);
            height: 1px;
            width: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
        }
        //.contourPage
        h4 {
            font-size: 30px;
            font-weight: bold;
            color: @darkGrey;
        }

        label {
            &.umbraco-forms-label {
                font-size: 22px;
                margin-top: 30px;
                margin-bottom: 5px;
                display: inline-block;
            }
        }

        small {
            font-size: 14px;
            margin-bottom: 15px;
            display: inline-block;
        }

        .radiobuttonlist {
            padding-top: 10px;
        }

        .radio {
            margin-bottom: 25px;

            label {
                cursor: pointer;
                box-sizing: border-box;
                line-height: 20px;
                padding: 10px 10px 10px 30px;
                border: 2px solid @darkGrey;
                border-radius: 5px;
                background-color: #e9e9ea;
                color: @darkGrey;

                &:hover {
                    border-color: @lightBlue
                }
            }

            @media @onlyMobile {
                label {
                    padding-left: 2.5em;
                }
            }

            input {
                &[type="radio"] {
                    position: absolute;
                    left: 20px;
                }

                &:checked + label {
                    border: 2px solid @lightBlue;
                    background-color: white;
                }
            }
        }

        .radiobuttonlist.dataconsent {

            label {
                margin-bottom: 5px;
                cursor: pointer;
                box-sizing: border-box;
                line-height: 20px;
                padding: 10px 10px 10px 30px;
                border: 2px solid #e9e9ea;
                border-radius: 5px;
                background-color: #e9e9ea;
                color: @darkGrey;
                display: inline-block;

                &:hover {
                    border-color: @lightBlue
                }
            }

            input {
                &[type="radio"] {
                    position: absolute;
                    left: 20px;
                    margin-top: 15px;
                }

                &:checked + label {
                    border: 2px solid @lightBlue;
                    background-color: white;
                }
            }
        }


        textarea {
            width: 100%;
            -moz-appearance: none;
            -webkit-appearance: none;
            min-height: 200px;
            font-size: 16px;
            box-sizing: border-box;
            border-radius: 5px;
            background-color: white;
            border: 2px solid @darkGrey;
            color: @darkGrey;
            line-height: 40px;
            height: 44px;
            padding: 0 26px 0 8px;
        }

        select {
            font-size: 16px;
            box-sizing: border-box;
            border-radius: 5px;
            background-color: white;
            border: 2px solid #a1a1a1;
            color: @darkGrey;
            line-height: 40px;
            height: 44px;
            padding: 0 26px 0 8px;
            background-image: url(/images/chevron.svg);
            background-repeat: no-repeat;
            background-position: center right 6px;
            background-size: 14px 14px;
            max-width: 100%;
            -moz-appearance: none;
            -webkit-appearance: none;
        }
    }
}


