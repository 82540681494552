﻿@import "_config";

.recreation{
    position: relative;
    .breadcrumbs{
        position: absolute;
        top: 0;
        z-index: 10;
        display: inline-block;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        margin-top: 10px;
        margin-left: 15px;
        border-radius: 2px;
        padding: 5px 10px;
        line-height: 18px;

        a{
            color: white;
            font-weight: normal;
            &:hover{
                text-decoration: underline;
            }
        }

        .divider{
            font-size: 13px;
            font-weight: 100;
            line-height: 18px;
        }
    }
}

#main-content.daysOut {
    .daysOutHeadingImage {
        width: 100%;
        height: 250px;
        overflow: hidden;
        position: relative;

        @media @tablet {
            height: 500px;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: center center;
            // object-fit polyfill
            font-family: 'object-fit: cover; object-position: center center';
        }

        .credit {
            margin-top: -39px;
            text-align: right;
            position: absolute;
            bottom: 16px;
            left: 0;
            right: 0;

            p {
                color: @lightGrey;
                background-color: black;
                background-color: rgba(0, 0, 0, 0.4);
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                display: inline;
                padding: 8px 20px 16px 20px;
            }
        }
    }

    .whatsHereListing {
        background-color: @lightGreen;

        @media @desktop {
            min-height: 150px;
            margin-bottom: 30px;
        }

        .constrainingContainer {
            position: relative;
        }

        .columnDesktop2 {
            padding-right: 0;
        }

        &.closed {
            overflow: hidden;

            h2 {
                &:before {
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }

        h2 {
            padding: 15px 0;
            color: white;
            font-family: @bodyFontFamily;
            font-weight: bold;
            font-size: 30px;

            @media @onlyMobile {
                &:before {
                    position: absolute;
                    content: '';
                    display: block;
                    width: 18px;
                    height: 18px;
                    background-image: url(/images/caret-up-white.svg);
                    background-repeat: no-repeat;
                    background-size: 18px 18px;
                    background-position: 100%;
                    right: 8px;
                    top: 21px;
                    -webkit-transform-origin: center center;
                    -ms-transform-origin: center center;
                    transform-origin: center center;
                    -webkit-transition: -webkit-transform 225ms ease-out;
                    transition: -webkit-transform 225ms ease-out;
                    transition: transform 225ms ease-out;
                    transition: transform 225ms ease-out,-webkit-transform 225ms ease-out;
                }
            }

            @media @tablet {
                margin-top: 15px;
            }

            @media @desktop {
                padding: 0;
                margin-top: 37.5px;
                line-height: 75px;
                border-right: 8px solid fadeout(@lightGrey, 60%);
            }
        }

        .triangle-down {
            font-size: 60px;
            color: @lightGreen;
            width: 100%;
            text-align: center;
            position: absolute;
            top: 100%;
            left: 0;

            @media @notDesktop {
                display: none;
            }
        }


        .featuredIconList {
            list-style: none;
        }

        .featuredIcon {
            float: left;
            margin-bottom: 20px;
            position: relative;
            width: 47%;
            margin-right: 3%;
            min-height: 58px;

            @media @tablet {
                width: 33.33333333%;
                margin-right: 0;
                min-height: initial;
                height: 50px;
            }

            @media @desktop {
                margin-right: 15px;
                margin-top: 50px;
                width: auto;

                .open {
                    display: block;
                }
            }

            span {
                padding-left: 10px;
                display: block;
                color: white;
                display: table-cell;
                vertical-align: middle;

                @media @notDesktop {
                    height: 50px;
                }

                i.triangle-down {
                    @media @notDesktop {
                        display: none;
                    }
                }

                @media @desktop {
                    display: none;
                    position: absolute;
                    top: -48px;
                    background-color: @darkGrey;
                    border-radius: 4px;
                    width: 190px;
                    left: -70px;
                    text-align: center;
                    padding: 10px 0;

                    i.triangle-down {
                        color: @darkGrey;
                        border: 0;
                        height: auto;
                        width: auto;
                        position: absolute;
                        left: 0;
                        bottom: -25px;
                        top: auto;
                        right: 0;
                        font-size: 20px;
                    }
                }
            }

            i {
                float: left;
                color: white;
                border: 2px solid white;
                border-radius: 10px;
                width: 50px;
                height: 50px;
                font-size: 47px;
                text-align: center;
                line-height: 47px;

                &.fa-visitorcentre, &.fa-toilets {
                    background-color: @lightBlue;
                }

                &.fa-orienteering {
                    border: none !important;
                    color: red !important;
                    font-size: 50px !important;
                    line-height: 51px !important;
                    background-color: white;
                    width: 48px;
                    height: 49px;
                    margin-top: -1px;
                }
            }

            label {
                left: -9999em;
                position: absolute;
            }
        }
    }

    .sidebar {
        background-color: transparent;

        #contentNavigation.closed {
            overflow: hidden;
            height: 40px !important;

            h2 {
                &:before {
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }

        h2 {
            font-size: 20px;

            @media @onlyMobile {
                &:before {
                    position: absolute;
                    content: '';
                    display: block;
                    width: 18px;
                    height: 18px;
                    background-image: url(/images/caret-up-black.svg);
                    background-repeat: no-repeat;
                    background-size: 18px 18px;
                    background-position: 100%;
                    right: 10px;
                    top: 32px;
                    -webkit-transform-origin: center center;
                    -ms-transform-origin: center center;
                    transform-origin: center center;
                    -webkit-transition: -webkit-transform 225ms ease-out;
                    transition: -webkit-transform 225ms ease-out;
                    transition: transform 225ms ease-out;
                    transition: transform 225ms ease-out,-webkit-transform 225ms ease-out;
                }
            }
        }

        div {
            padding: 0;
        }

        ol {
            list-style-type: none;
            margin: 0;

            .column {
                padding: 0;

                li {
                    border-bottom: 1px solid @lightGrey;
                    padding: 15px 0;

                    &:hover {
                        border-bottom: 1px solid @lightBlue;
                    }

                    &.active {
                        border-bottom: 1px solid @darkGrey;
                    }

                    a {
                        float: none;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    #featuredContent {
        margin: 30px 0;

        @media @tablet {
            margin: 60px 0;
        }
    }

    .onTheWebLinks {
        background-color: @lightGrey;
        padding: 15px 0 30px 30px;

        .socialIcon {
            background-color: @darkGrey;
            width: 36px;
            height: 36px;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            font: 0/0 a;
            background-position: center center;
            background-size: 22px 22px;
            background-repeat: no-repeat;
            margin-left: 13px;

            &:hover {
                background-color: @midGrey;
            }

            &.Facebook {
                background-image: url('/images/facebook-logo-light.svg');
                background-size: 23px 23px;
                margin-left: 0;
            }

            &.Twitter {
                background-image: url('/images/twitter-logo-light.svg');
                background-size: 21px 21px;
            }

            &.Linkedin {
                background-image: url('/images/linkedin-logo-light.svg');
                background-size: 19px 19px;
            }

            &.Instagram {
                background-image: url('/images/instagram-logo-light.svg');
                background-size: 19px 19px;
            }
        }
    }
}