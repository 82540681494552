﻿@import "_config";

.popularContentBox {
    width: 100%;
    float: left;
    color: white;
    margin-bottom: 8px;

    @media @desktop {
        //width: 50%;
        margin-bottom: auto;
    }

    @media @tablet {
        &, a {
            min-height: 180px;
        }
    }

    h3, p {
        color: white;
    }

    h3 {
        line-height: 27px;
    }
    //&:nth-child(2) {
    &:first-child + .popularContentBox { // IE8 compatible
        margin-right: 30px;
        background-color: @aqua;
    }

    &.wide {
        width: 100%;
        background-image: none;
        position: relative;

        @media @tablet {
            min-height: 180px;
            background-repeat: no-repeat;
            background-size: 48%;
            background-position: center right;
            margin-bottom: 30px;
            /*&:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 50%;
                background-image: linear-gradient(to left, rgba(2, 122, 139, 0.75), rgba(2, 122, 139, 0.18));
            }*/
        }

        p {
            margin-top: 10px;
            width: 100%;
        }

        a {
            position: relative;

            @media @tablet {
                padding-right: 52%;
                padding-right: calc(~"50% + 15px");
            }
        }

        &.withGraphic.lightGreen {
            background-size: 88%;
            background-position: 490%;
            background-color: @lightGreen;
            background-blend-mode: soft-light;
        }
    }

    a {
        color: inherit;
        width: 100%;
        height: 100%;
        padding: 18px 15px 18px 15px;
        display: block;

        &:hover {
            text-decoration: none;
            background-color: rgba(0,0,0,0.1);
        }
    }
}