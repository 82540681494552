﻿@import "_config";

.relatedLinksBox {
    background-color: @lightGrey;
    padding: 10px 4px;

    h3 {
        padding: 0;
        margin-bottom: 15px;
    }

    &:after {
        content: '';
        display: table;
        clear: both;
    }

    a {
        display: block;
        margin-bottom: 15px;
        color: @aquaOnLightGrey;
        font-weight: normal;
    }

    /*a.external:after {
        content: '\f01f';
        margin-left: 8px;
        display: inline;
        font-family: 'NewWebsite';
        font-size: inherit;
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        text-rendering: auto;
    }*/
}

@media @tablet {
    .relatedLinksBox {
        padding: 10px 0;
    }
}