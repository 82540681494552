﻿@import "_config";

.landing #featuredContent{
    margin-bottom: 0;
    margin-top: -4px;
    padding: 57px 0 26px 0;
}

#featuredContent {
    color: white;
    padding: 20px 0 10px 0;

    @media @tablet {
        padding: 38px 0 26px 0;
        margin-bottom: 30px;
    }

    .constrainingContainer {
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }

    h2 {
        color: white;
        line-height: 1;
    }

    .featured {
        margin-bottom: 8px;
        p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;    
        }
        &.wide {
            &:nth-child(3) {
                margin-left: 30px;
            }
            p{
                overflow: initial;
                white-space: normal;
                text-overflow: unset;    
            }

            @media @onlyMobile {
                &:nth-child(3) {
                    margin-top: 10px;
                    margin-left: 0;
                }
            }
        }

        width: 100%;
        display: inline-block;

        @media @tablet {
            margin-bottom: 30px;
        }
    }
}

.featured {
    &.wide {
        width: 100%;
        background-size: 100% auto;
        height: auto;
        position: relative;

        .imageWrapper {
            position: relative;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 0;

            @media @tablet {
                position: absolute;
                height: 100%;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-image: linear-gradient(to right, rgba(31, 31, 31, 0.91), rgba(255, 255, 255, 0));
                    pointer-events: none;
                    z-index: 1;
                }
            }
        }

        @media @onlyMobile {
            background-image: none !important;
            background-color: @featuredBoxDarkGreen;
        }

        @media @tablet {
            height: 340px;
        }

        h3 {
            position: relative;
            line-height: 37 / 36;

            @media @tablet {
                font-size: 36px;
                margin-bottom: 14px;
                padding-bottom: 14px;
                width: 60%;
                width: calc(~"66.6667% - 15px");
            }

            @media @desktop {
                padding-top: 20px !important;
                margin-left: 30px;
                width: calc(~"66.6667% - 30px");
            }
        }

        p {
            display: block;
            font-size: 15px;
            position: relative;

            @media @tablet {
                font-size: 16px;
            }

            @media @desktop {
                padding: 0 30px;
                padding-right: 0;
                width: 66.6667%;
            }
        }

        @media @desktop {
            .tag {
                padding: 5px 5px 5px 30px;
            }
        }
    }

    position: relative;
    //min-height: 250px;
    background-color: @featuredBoxDarkGreen;
    overflow: hidden;

    @media @onlyMobile {
        margin-left: 0;

        &:nth-child(3) {
            margin-right: 10px;
        }
    }

    @media @tablet {
        height: 340px;
        min-height: unset;

        .imageWrapper + .text,
        .dates + .text {
            max-height: 134px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &.wide, &.sideImage {
            .imageWrapper + .text,
            .dates + .text {
                max-height: none;
                text-overflow: ellipsis;
                overflow: visible;
            }
        }
    }

    a {
        color: inherit;
        display: block;
        min-height: 100%;
        max-height: 100%;
        height: 100%;
        padding-bottom: 53px;

        @media @tablet {
            padding-bottom: 53px + 10px;
        }

        &:hover {
            background-color: rgba(0,0,0,0.1);
            text-decoration: none;

            img {
                transform: scale(1.1);
            }
        }
    }

    &.noTag a {
        padding-bottom: 10px;

        .imageWrapper + .text,
        .dates + .text {
            max-height: none;
            text-overflow: ellipsis;
            overflow: visible;
        }
    }

    .tag {
        background-color: @darkGreen;
        padding: 5px 5px 5px 15px;
        position: absolute;
        z-index: 2;
        bottom: 15px;
    }

    h2, h3 {
        padding-top: 10px !important;
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 5px;
        margin-bottom: 8px;
        color: white;
        line-height: 30 / 24;
    }

    p {
        display: none;
        padding: 0 15px;
        color: white;

        @media @tablet {
            display: block;
        }

        &.date {
            display: block;
            margin-bottom: 50px;
        }
    }

    .imageWrapper {
        width: 100%;
        height: 110px;
        overflow: hidden;

        @media @tablet {
            height: 152px;
        }

        img {
            width: 100%;
            //transform: translateY(-15%);
            height: 100%;
            object-fit: cover;
            object-position: center center;
            // polyfill
            font-family: 'object-fit: cover; object-position: center center;';
            transition: transform 225ms ease-out;
            transform-origin: center center;

            @media @tablet {
                transform: initial;
            }
        }
    }

    .imageWrapper + .dates {
        margin-top: -76px;
        position: absolute;
    }

    @media @onlyMobile {
        .imageWrapper + .dates {
            margin-top: -33px;
        }

        &.mobileSideImage {
            padding-bottom: 0;

            & > a {
                padding-left: 135px;
                padding-bottom: 8px;
                min-height: 72px + 8px + 8px + 30px + 8px; // image height + padding above image + padding below image + tag height + padding below tag
            }

            .tag {
                left: 0;
                bottom: 8px;
            }

            .imageWrapper {
                width: 127px;
                height: 72px;
                position: absolute;
                left: 8px;
                top: 8px;
            }

            .imageWrapper + .dates {
                margin-top: 0;
                left: 8px;
                top: 72px + 8px - 33px;
            }
        }
    }

    @media @tablet {
        &.sideImage {
            min-height: 0;
            height: auto;

            & > a {
                padding-bottom: 8px;
                padding-left: 50%;
                height: 100%;
                min-height: 152px;
            }

            .imageWrapper {
                width: 50%;
                width: calc(~"50% - 15px");
                left: 0;
                top: 0;
                position: absolute;
            }

            h3 {
                margin-left: 15px;
            }

            p {
                padding-left: 15px;
            }

            .tag {
                display: none;
            }

            .imageWrapper + .dates {
                left: 0;
                bottom: 0;
                margin-top: 0;
            }
        }
    }
}

#featuredContent.darkGreen {
    background-color: @featuredSectionDarkGreen;

    .featured {
        background-color: @featuredBoxDarkGreen;

        .tag {
            background-color: @featuredSectionDarkGreen;
        }
    }
}

.featured {
    border: @darkGrey 1px solid;
    box-shadow: 0 0 0 1px white;
}

.featuredHeading {
    margin-bottom: 3px;
}