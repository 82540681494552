﻿@import "_config";

//// BASE STYLES

.column {
    padding-left: 4px;
    padding-right: 4px;
    float: left;
    position: relative;

    @media @tablet {
        padding-left: 15px;
        padding-right: 15px;
    }

    &.row {
        padding-left: 0;
        padding-right: 0;
    }
}

.row {

    // Clearfix
    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

.constrainingContainer {
    margin: 0 auto;
    padding-left: 4px;
    padding-right: 4px;
    width: 100%;

    &:after {
        clear: both;
        display: table;
        content: '';
    }

    &.column {
        padding-left: 8px;
        padding-right: 8px;
        float: none;
    }
}

//// RESPONSIVE STYLES
// Mobile first
.generate-columns(@gridColumns);

@media @tablet {
    .generate-columns(@gridColumns, ~"Tablet");

    .constrainingContainer {
        //width: @tabletWidth;
        padding-left: 0;
        padding-right: 0;

        &.column {
            padding-left: @gridOuterPadding;
            padding-right: @gridOuterPadding;
        }
    }
}

@media @desktop {
    .generate-columns(@gridColumns, ~"Desktop");

    .constrainingContainer {
        max-width: @desktopWidth;
    }
}

//// MIXINS

.generate-columns(@n, @media: ~"", @i: 1) when (@i =< @n) {
    .column@{media}@{i} {
        width: (@i * 100% / @n);
    }

    .generate-columns(@n, @media, (@i + 1));
}