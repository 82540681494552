﻿@import "_config";

#fwo.content {
    position: relative;
    margin-top: 20px;

    h2 {
        clear: left;
    }
}

#fwo {
    #activefloodwarningtable {
        float: left;
        width: 100%;
        margin-bottom: 40px;

        table {
            font-size: 16px;
            border-collapse: collapse;

            tr {
                height: 94px;
                background-color: white;

                &:first-child {
                    height: 50px;
                    background-color: @lightBlue;

                    td, th {
                        padding: 0;
                        color: white;

                        a {
                            color: white;
                            font-weight: bold;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                td, th {
                    text-align: center;
                    padding: 0;
                    border: 0;

                    a:hover {
                        text-decoration: underline;
                    }

                    img {
                        margin: 0;
                        border: 5px solid white;
                        max-width: none;
                    }

                    p {
                        margin: 0;
                        color: @aqua;
                    }

                    p.subText {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    iframe {
        width: 100% !important;
    }

    .tab-content {
        background-color: white;
        padding: 20px;
        clear: left;
        margin-top: 44px;
        position: relative;
        float: left;
        width: 920px;
    }

    .tab-content.map {
        width: 960px;
        padding: 0;
    }

    ul.tabs {
        float: left;
        clear: both;
        margin: 0;
        padding: 0;

        li {
            float: left;
            list-style-type: none;
            position: absolute;
            padding: 10px 10px;
            width: 220px;
            text-align: center;
            border: 2px solid @lightBlue;
            border-bottom: none;
            margin-right: -2px;
            background: none;
            background-color: @lightGrey;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            a {
                text-decoration: none;
                color: black;
                font-size: 15px;
            }
        }

        li.threeDayForecast {
            left: 242px;
        }

        li.floodRiskMap {
            left: 484px;
        }

        li.weatherTraffic {
            left: 716px;
        }

        li.active {
            background-color: white;
        }
    }

    ul.tabs.mobile {
        display: none;
    }
}

#flood-warnings-list {

    label {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .tab-content {
        background-color: white;
        padding: 20px;
        clear: left;
        margin-top: 44px;
        position: relative;
        float: left;
        width: 920px;
    }

    .tab-content.map {
        width: 960px;
        padding: 0;
    }

    ul.tabs {
        float: left;
        clear: both;
        margin: 0;
        padding: 0;

        li {
            float: left;
            list-style-type: none;
            position: absolute;
            padding: 10px 10px;
            width: 220px;
            text-align: center;
            border: 2px solid @lightBlue;
            border-bottom: none;
            margin-right: -2px;
            background: none;
            background-color: @lightGrey;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            a {
                text-decoration: none;
                color: black;
                font-size: 15px;
            }
        }

        li.floodWarningsMap {
            left: 0;
        }

        li.threeDayForecast {
            left: 242px;
        }

        li.floodRiskMap {
            left: 484px;
        }

        li.weatherTraffic {
            left: 716px;
        }

        li.active {
            background-color: white;
        }
    }

    input#fwoSearch {
        padding: 10px;
        width: 430px;
        float: right;
        margin-bottom: 20px;
        border: 1px solid @lightBlue;
    }

    #fwoSearchResults {
        .warning {
            display: block !important;
        }
    }

    .warning {
        width: 100%;
        clear: both;
        float: left;
        margin-bottom: 20px;
        border-bottom: 2px solid @lightGrey;

        &:hover {
            border-bottom: 2px solid @lightBlue;
        }

        &:hover {
            p.name {
                text-decoration: underline;
            }
        }

        a {
            width: 95%;
            height: 100%;
            float: left;
            background-color: white;
            padding: 2.5%;
            margin-bottom: 15px;

            &:hover {
                text-decoration: none;
            }

            .alertArea {
                width: 25%;
                height: 100%;
                float: left;

                img {
                    float: left;
                }

                span {
                    color: @darkGrey;
                }
            }

            .alertInfo {
                width: 70%;
                margin-left: 5%;
                height: 100%;
                float: left;

                p {
                    margin: 0 !important;
                    clear: left;
                }

                p.name {
                    color: @aqua;
                    font-weight: bold;
                    font-size: 18px;
                }
            }
        }
    }

    .paginationControls {
        display: none;
        float: left;
        width: 100%;
        text-align: center;
        position: relative;
        margin: 0 0 40px 0;

        .pagination {
            a {
                margin: 0 5px;
            }

            a.current {
                font-weight: bold;
            }
        }

        a#previous {
            float: left;
        }

        a#next {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

#mfwo {
    ul.tabs {
        li.floodWarningsMap {
            left: 0;
        }
    }
}

#three-day-forecast {
    position: relative;

    h2 {
        float: left;
        font-weight: bold;
        font-size: 26px;
        padding: 20px 0;
        color: @darkGrey;
        line-height: initial;
    }

    p {
        clear: both;
    }

    pre {
        float: left;
        clear: both;
        width: 100%;
        white-space: pre-wrap;
        font-size: 16px;
    }

    .ea-widget {
        width: 275px;
        height: 220px;
        float: left;
        overflow: hidden;
        margin-left: -10px;
    }

    table.forecast-data-table {
        tr {
            &:first-child {
                background-color: white;
                color: inherit;
            }

            &:not(:first-child) {
                &:hover {
                    background-color: white;
                }
            }

            &:last-child {
                border-bottom: 1px solid @lightGrey;
            }
        }

        td {
            vertical-align: top;
            padding: 10px;
            border-top: 1px solid @lightGrey;
            border-right: 1px solid @lightGrey;
            width: 211px;

            ul {
                margin-left: 0;
                list-style: none;
            }

            p {
                margin: 0;
            }

            p.dayLabel {
                display: none;
            }

            &:last-child {
                border-right: 0;
                border-right: 1px solid @lightGrey;
                border-right: 1px solid @lightGrey;
            }

            &:first-child {
                border-top: 0;
            }
        }

        td.risk {
            position: relative;
            width: 293px;

            p {
                color: @darkGrey;
            }

            h3 {
                font-size: 18px;
                padding: 0;
                padding-bottom: 10px;
                color: @darkGrey;
            }

            img {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 20px;
                cursor: pointer;
                background-color: @darkGrey;
                padding: 6px;
                border: 0;
                margin: 0;
                border-radius: 4px;
            }

            .forecast-popup {
                position: absolute;
                background-color: @lightBlue;
                z-index: 1;
                width: 350px;
                padding: 10px 20px;
                border-radius: 5px;
                margin-left: -3px;
                top: 30%;
                left: 237px;
                padding-top: 35px;

                a.close {
                    color: white;
                    font-weight: bold;
                    float: right;
                    font-size: 12px;
                    text-decoration: underline;
                    position: absolute;
                    top: 10px;
                    right: 20px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                p {
                    font-size: 14px;
                    margin: 0;
                    margin-left: 0 !important;
                    color: white;

                    strong {
                        font-weight: bold;
                    }
                }

                ul {
                    font-size: 14px;
                    margin: 0;
                    margin-left: 0 !important;
                    color: white;
                    margin-left: 20px !important;

                    strong {
                        font-weight: bold;
                    }
                }

                li {
                    font-size: 14px;
                    margin: 0;
                    margin-left: 0 !important;
                    color: white;
                    background-image: none;
                    list-style-type: disc;
                    padding-left: 0;

                    strong {
                        font-weight: bold;
                    }
                }

                span {
                    font-size: 14px;
                    margin: 0;
                    margin-left: 0 !important;
                    color: white;

                    strong {
                        font-weight: bold;
                    }
                }

                a {
                    font-size: 14px;
                    margin: 0;
                    margin-left: 0 !important;
                    color: white;

                    strong {
                        font-weight: bold;
                    }
                }
            }
        }

        td.risk.high {
            background-color: @warningRed;

            p {
                color: white;
            }

            h3 {
                color: white;
            }
        }

        td.risk.medium {
            background-color: @warningDarkYellow;

            p {
                color: white;
            }

            h3 {
                color: white;
            }
        }

        td.risk.low {
            background-color: @warningLightyellow;
        }

        td.risk.veryLow {
            background-color: @warningGreen;
        }
    }

    .forecast-container {
        display: inline-block;
        margin-top: 40px;
        background-color: white;
        width: 100%;

        .forecast-day {
            width: 100%;
            float: left;
            border: 2px solid @aqua;
            margin-top: -4px;
            border-top: none;
            padding: 14px 10px 10px;
            border: 2px solid @darkGrey;
            margin-top: -4px;
            border-top: none;
            padding: 14px 10px 10px;

            h3 {
                text-align: left;
                font-size: 20px;
                padding-top: 0;
                margin: 0;
            }

            img {
                clear: both;
                width: 27%;
                padding-right: 6%;
                max-width: 100%;
                margin: 0;
                border: 0;
                float: right;
                display: block;
                margin: 0 auto;
            }

            .risks {
                width: 100%;

                .risk {
                    width: 80%;
                    border: 1px solid @warningDarkGrey;
                    padding: 10px 5%;
                    border-top: none;
                    position: relative;
                    float: left;
                    margin-left: 10px;

                    &:first-child {
                        border-top: 1px solid @warningDarkGrey;
                    }

                    img {
                        width: 20px;
                        float: left;
                        clear: none;
                        position: relative;
                        margin: auto;
                        margin-left: -3px;
                        cursor: pointer;
                        background-color: @lightGrey;
                        padding: 4px;
                        border-radius: 4px;
                    }

                    .forecast-popup {
                        position: absolute;
                        background-color: @lightGrey;
                        z-index: 1;
                        width: 350px;
                        padding: 10px 20px;
                        border-radius: 5px;
                        margin-left: -3px;

                        a.close {
                            color: white;
                            font-weight: bold;
                            float: right;
                            font-size: 12px;
                            text-decoration: underline;

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        p {
                            font-size: 12px;
                            margin: 0;
                            margin-left: 0 !important;
                            color: white;

                            strong {
                                font-weight: bold;
                            }
                        }
                    }

                    p {
                        margin: 0;
                        margin-left: 30px;
                    }
                }
            }
        }
    }

    h1.forecast-day-title {
        display: none;
        display: none;
    }
}

table.forecast-data-table {
    background-color: white;
    width: 100%;

    @media @tablet {
        width: 65% !important;
    }
}

#flooding-detail {
    table {
        margin-bottom: 40px;
        background-color: white;

        tr {
            background-color: white;

            td {
                vertical-align: top;
                padding: 10px;

                img {
                    border: 0;
                    margin: 0;
                    padding-right: 10px;
                }

                p {
                    margin: 0 !important;
                }

                p.severity {
                    font-size: 20px;
                }

                ul {
                    margin-left: 0;
                    list-style-type: none;
                }

                table#status-history {
                    width: 100%;

                    tr {
                        height: 20px;

                        td {
                            &:first-child {
                                width: 70%;
                            }

                            border: none;
                            background-color: white !important;
                            color: @darkGrey;
                            font-weight: normal;
                            padding: 0;
                            font-size: 16px;
                            width: 50%;
                        }
                    }
                }
            }
        }

        tr.header {
            background-color: @lightBlue !important;
            height: 40px;

            p {
                color: white;
                font-weight: bold;
                font-size: 20px;
            }
        }
    }
}

#weather-traffic-updates {
    .ea-widget {
        float: left;
        width: 50%;
    }

    .weatherContent {
        width: 50%;
        float: left;

        ul {
            float: left;
            clear: left;
        }

        h2 {
            float: left;
            clear: left;
            font-weight: bold;
            font-size: 26px;
            padding: 20px 0;
            color: @darkGrey;
            line-height: initial;
        }

        p {
            clear: left;
        }
    }
}

#day-2 {
    display: none;
    display: none;
}

#day-3 {
    display: none;
    display: none;
}

#day-4 {
    display: none;
    display: none;
}

#day-5 {
    display: none;
    display: none;
}

.forecast-container {
    ul#tabs {
        margin: 0;
        margin: 0;
        width: 100%;
        float: left;
        padding: 0;
    }

    #tabs {
        li {
            width: 20%;
            float: left;
            background-image: none;
            padding: 10px;
            cursor: pointer;
            border: 2px solid #0091a6;
            height: 74px;
            list-style-type: none;
            float: left;
            padding: 10px;
            margin-bottom: -4px;
            border: 2px solid @darkGrey;
            border-left: none;
            height: 74px;

            &:first-child {
                border-left: 2px solid @darkGrey;
            }

            h2 {
                text-decoration: none;
                color: @darkGrey;
                font-size: 18px;
                font-weight: 700;
                text-decoration: none;
                color: @darkGrey;
                font-size: 18px;
                font-weight: 700;
                display: inline;
                clear: unset;
                margin: 0px;
                float: unset;
                padding: 0px
            }

            p {
                text-decoration: none;
                color: @darkGrey;
                font-size: 18px;
                font-weight: 700;
                font-size: 16px;
                font-weight: 400;
                text-decoration: none;
                color: @darkGrey;
                font-size: 18px;
                font-weight: 700;
                font-size: 16px;
                font-weight: 400;
            }
        }

        li.active {
            border-bottom: 4px solid white;
            background-color: white;

            a {
                color: @darkGrey;
            }

            p {
                color: @darkGrey;
            }
        }

        .high {
            background-color: @warningRed;
            color: white;
            background-color: @warningRed;
            color: white;

            a {
                color: white;
                color: white;
            }

            p {
                color: white;
                color: white;
            }
        }

        .medium {
            a {
                color: white;
            }

            p {
                color: white;
            }
        }
    }

    .mobileDayHeading {
        display: none;
    }

    .medium {
        background-color: @warningOrange;
        background-color: @warningOrange;
    }

    .low {
        background-color: @warningLightyellow;
        background-color: @warningLightyellow;
    }

    .veryLow {
        background-color: @warningGreen;
        background-color: @warningGreen;
    }

    table {
        width: 65%;
        float: left;
        width: 65%;
        float: left;
    }
}

#flood-forecast-tool {
    position: relative;
    margin: 20px 0;
    width: 100%;
    float: left;
    position: relative;
    margin: 20px 0;
    width: 100%;
    float: left;

    div {
        width: 100%;

        @media @tablet {
            width: 15%;
        }

        padding: 20px 10px;
        float: left;
        margin-right: 20px;

        a {
            color: @darkGrey;
            text-decoration: none;
            margin: 0;
            color: @darkGrey;
            text-decoration: none;
            margin: 0;
        }

        p {
            color: @darkGrey;
            text-decoration: none;
            margin: 0 !important;
            line-height: 20px !important;
        }
    }

    .updated {
        margin-right: 0;
        text-align: right;
        background-color: @aqua;

        @media @tablet {
            float: right;
        }

        p {
            color: white;
            padding: 0 10px 0 0;
            color: white;
            padding: 0 10px 0 0;
        }
    }

    .high {
        background-color: @warningRed;

        a {
            color: white;
        }

        p {
            color: white;
        }
    }

    .medium {
        background-color: @warningOrange;

        a {
            color: white;
        }

        p {
            color: white;
        }
    }

    .low {
        background-color: @warningLightyellow;
    }

    .veryLow {
        background-color: @warningGreen;
    }
}

@media screen and (max-width:1000px) {
    #fwo.content {
        #flooding-map-of-wales {
            float: none;
            //width: 250px;
            height: 330px;
            margin: 0 auto;
            padding-right: 0;
            width: auto;

            .imageWrapper {
                margin: 0 auto;
                width: 226px;
                float: none;
                margin-top: 10px;
            }
        }
    }

    #fwo {
        #activefloodwarningtable {
            width: 100%;
        }

        ul.tabs {
            width: 100%;

            li {
                float: left;
                clear: both;
                position: relative;
                left: inherit !important;
                width: 100%;
                padding: 10px 0;
                border: 2px solid @lightBlue;
                border-radius: 5px;
                margin-bottom: 10px;
            }

            li.threeDayForecast {
                display: none;
            }

            li.weatherTraffic {
                display: none;
            }

            li.active {
                background-color: @lightGrey;
            }
        }

        ul.tabs.mobile {
            display: block;

            li.threeDayForecast {
                display: block;
            }

            li.weatherTraffic {
                display: block;
            }
        }

        #flood-warnings-map {
            display: none !important;
        }

        #flood-risk-map {
            display: none !important;
        }

        #weather-traffic-updates {
            width: 90%;
            padding: 20px 5%;
            margin-top: -10px;

            .ea-widget {
                width: 100%;
                float: left;
            }

            .weatherContent {
                width: 100%;
                float: left;
            }
        }

        #three-day-forecast {
            width: 90%;
            padding: 20px 5%;
            margin-top: -10px;

            .forecast-container {
                width: 100%;
                margin-top: 40px;

                .forecast-day {
                    width: 33%;
                    float: left;
                    margin-bottom: 40px;

                    h3 {
                        text-align: left;
                        font-size: 25px;
                        padding-top: 0;
                        word-wrap: break-word;
                    }
                }
            }

            table.forecast-data-table {
                tr {
                    display: block;
                    width: 100%;
                    float: left;
                    margin-top: 20px;
                    height: initial;
                    height: auto;

                    td {
                        display: block;
                        width: 90%;
                        padding: 5%;
                        border: none;

                        p.dayLabel {
                            float: left;
                            font-weight: bold;
                            display: block;
                        }

                        ul {
                            li {
                                float: left;
                                clear: left;
                            }
                        }
                    }

                    td.risk {
                        .forecast-popup {
                            margin-left: 0;
                            left: 0;
                            padding: 10px 10%;
                            width: 82%;
                            padding-top: 35px;
                        }

                        img {
                            width: auto !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:620px) {
    #flood-warnings-list {
        input#fwoSearch {
            width: 100%;
        }

        .warning {
            a {
                .alertArea {
                    width: 100%;
                    height: auto;
                }

                .alertInfo {
                    width: 100%;
                    margin-left: 0;
                    height: auto;
                }
            }
        }

        .paginationControls {
            .pagination {
                float: left;
                clear: left;
                width: 100%;
                margin-top: 10px;
            }
        }
    }

    #flooding-detail {
        table {
            tr {
                td {
                    p.statusHistory {
                        width: 164%;
                        margin-left: -64% !important;
                    }

                    table#status-history {
                        width: 164%;
                        margin-left: -64%;
                        margin-top: 32%;

                        tr {
                            margin-bottom: 40px;
                            float: left;
                        }
                    }
                }
            }
        }
    }

    #fwo.content {
        #flooding-map-of-wales {
            padding-right: 10%;

            .imageWrapper {
                margin-top: 0;
            }
        }
    }

    #fwo {
        #activefloodwarningtable {
            table {
                tr {
                    td {
                        img {
                            display: none;
                        }

                        p.subText {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1000px) {
    #fwo {
        #three-day-forecast {
            width: 100%;
            padding: 20px 0;
            width: 100%;
            padding: 20px 0;

            .forecast-container {
                .forecast-day {
                    display: block !important;
                    float: left;
                    width: 100%;
                    clear: both;
                    border: none;
                    display: block !important;
                    float: left;
                    width: 100%;
                    clear: both;
                    border: none;
                }
            }

            table.forecast-data-table {
                tr {
                    td {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    .forecast-container {
        #tabs {
            display: none;
            display: none;
        }

        table {
            width: 95%;
            width: 95%;
        }

        .mobileDayHeading {
            display: block;
        }
    }

    #three-day-forecast {
        .forecast-container {
            .forecast-day {
                img {
                    width: 40% !important;
                    float: none;
                    margin: 0 auto;
                    width: 40% !important;
                    float: none;
                    margin: 0 auto;
                }
            }
        }

        h1.forecast-day-title {
            display: block;
            display: block;
        }
    }

    #flood-forecast-tool {
        div {
            margin-bottom: 20px;

            &:nth-child(2n) {
                margin-right: 0;
                margin-right: 0;
            }
        }
    }
}

.clear-float{
    clear: both;
}