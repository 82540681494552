﻿@import "_config";

@floodWarningsStatusItemIconWidth: 55px;

.floodWarningsStatus {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: white;
    line-height: 1;
    background-color: @lightGrey;
    padding: 0 4px; // should be 8px but cells have 4px margin
    margin: 10px -8px;
    color: @bodyTextColour;
    overflow: hidden;
}

.no-flexwrap .floodWarningsStatus {
    display: table;
}

.js .floodWarningsStatus {
    height: 53px;
    clear: both;

    &.floodWarningsStatusOpen {
        height: auto;
    }
}

.floodWarningsStatusCell {
    /*background-color: @transparentDarkLayer; // IE8 non-rgba
    background-color: fade(@transparentDarkLayer, 40%);*/
    padding: 8px;
    margin-left: 8px;
}

.floodWarningsStatusHeading {
    font-size: 22px; //28px;
    color: @aquaOnLightGrey;
    margin: 0 -4px;
    line-height: 53px;
    padding: 0 8px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 100%;

    a {
        @media @desktop {
            color: white;
        }

        font-size: 24px;
        display: block;

        @media @onlyMobile {
            width: 88%;
        }

        @media @tablet {
            font-size: 28px;
        }


        &:hover {
            text-decoration: none;
        }
    }

    &:before {
        position: absolute;
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background-image: url('/images/caret-down-black.svg');
        background-repeat: no-repeat;
        background-size: 18px 18px;
        background-position: right center;
        right: 20px;
        top: ((53px - 18px ) / 2); // height - icon height / 2
        transform-origin: center center;
        transition: transform 225ms ease-out;
    }

    .floodWarningsStatusOpen &:before {
        transform: rotate(180deg);
    }
}

.floodWarningsStatusUpdated {
    margin: 0 -4px;
    padding: 20px 8px;
    clear: both;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 100%;

    span.dateTimeLabel {
        font-size: 12px;
        margin-bottom: 5px;
    }

    span.dateTimeValue {
        width: 8em;
    }

    br {
        display: none;
    }
}

@media @desktop {
    .transparent {
        .floodWarningsStatusCell {
            background-color: @transparentDarkLayer; // IE8 non-rgba
            background-color: fade(@transparentDarkLayer, 60%);
            color: white;

            &.floodWarningsStatusHeading {
                color: @lightBlue;
            }
        }
    }
}

.floodWarningsStatusItem {
    padding: 0;
    background-position: 5px 10px;
    background-size: 40px;
    background-repeat: no-repeat;
    white-space: nowrap;
    margin: 0 4px;
    background-color: white;
    color: @bodyTextColour;
    flex-grow: 1;
    flex-shrink: 0;
    font-size: 0;
    max-width: calc(~"100% / 3 - 8.5px");
    flex-basis: calc(~"100% / 3 - 8.5px");
    float: left;

    .no-csscalc & {
        width: 30%;
    }

    a {
        color: inherit;
    }

    &.floodWarningsStatusCellActive {
        color: white;
    }

    &-severe_flood_warning {
        background-image: url('/images/flood-warnings-severe.gif');

        &.floodWarningsStatusCellActive {
            background-color: @warningWidgetRed !important;
        }
    }

    &-flood_warning {
        background-image: url('/images/flood-warnings-warning.gif');

        &.floodWarningsStatusCellActive {
            background-color: @warningWidgetRed !important;
        }
    }

    &-flood_alert {
        background-image: url('/images/flood-warnings-alert.gif');

        &.floodWarningsStatusCellActive {
            background-color: @warningWidgetOrainge !important;
        }
    }
}

[lang="en-gb"] .floodWarningsStatusItem-flood_alert {
    //word-spacing: 999999px; // this forces "Flood" & "alert" onto different lines
}

.floodWarningsStatusCellLink {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    color: white;
    height: 100%;
    padding: 10px;
}

.floodWarningsStatusCellNumber {
    font-size: 40px;
    padding-left: 40px;
    padding-bottom: 10px;
    line-height: 40px;
    font-weight: bold;

    &.longNumber {
        font-size: 30px;
    }
}

.floodWarningsStatusCellText {
    font-size: 16px;
    white-space: normal;
    flex-shrink: 1; //IE10 fix
    display: block;
    width: 100%;
}

.floodWarningsStatusCellActive {
    & .floodWarningsStatusCellText,
    & .floodWarningsStatusCellNumber {
        font-weight: bold;
    }
}

@media screen and (min-width: @mobileWidth) {
    .floodWarningsStatusItem {
        background-size: @floodWarningsStatusItemIconWidth;
    }

    .floodWarningsStatusCellNumber {
        padding-left: @floodWarningsStatusItemIconWidth;
        line-height: @floodWarningsStatusItemIconWidth;
        font-size: 47px;

        &.longNumber {
            font-size: 36px;
        }
    }
}

@media @tablet {
    .floodWarningsStatus {
        margin-left: -15px;
        margin-right: -15px;
        padding: 0 10px; // should be 15px but cells have 5px margin
    }

    .floodWarningsStatusHeading {
        margin: 0 -10px;
        padding: 0 15px;
    }

    .floodWarningsStatusItem {
    }
}

@media @desktop {
    .floodWarningsStatus {
        flex-wrap: nowrap;
        padding: 0;
        height: auto;
        margin: 0;
        min-height: 67px;
    }

    .transparent .floodWarningsStatus {
        background-color: transparent;
        color: white;
    }

    .js .floodWarningsStatus {
        height: auto;
    }

    .floodWarningsStatusCell {
        margin-left: 2px;
        margin-right: 0;
        padding: 0 20px;
        max-width: none;
        flex-basis: 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        flex-shrink: 1;

        &:first-child {
            margin-left: 0;
        }
    }

    .floodWarningsStatusCellLink {
        padding: 6px 0;
        padding-left: @floodWarningsStatusItemIconWidth + 10px;
        padding-right: 6px;
        align-items: center;
        flex-direction: row;
    }

    .floodWarningsStatusCellText {
        align-self: center;
        flex-basis: auto;
        flex-shrink: 1;
        padding-left: 10px;
    }

    .floodWarningsStatusHeading {
        flex-basis: 0;
        font-size: 28px;
        line-height: 1;
        cursor: default;

        &:before {
            display: none;
        }
    }

    .floodWarningsStatusItem {
        background-size: @floodWarningsStatusItemIconWidth;
        background-position: 5px center;
        padding: 0;
        float: none;

        &:first-child {
            margin-left: 2px;
        }
    }

    .floodWarningsStatusUpdated {
        flex-shrink: 20;
        flex-grow: 0;
        flex-basis: 150px;
        word-break: normal;

        br {
            display: initial;
        }
    }

    .floodWarningsStatusCellNumber {
        flex-basis: auto;
        padding: 0;
        flex-shrink: 0;
        flex-grow: 0;

        &.longNumber {
            font-size: 47px;
        }
    }
}

.floodWarningsStatusError {
    height: auto !important;
    background-color: white !important;
    padding: 5px !important;

    p {
        color: black;
        margin-left: 0.5em;
    }
}