﻿.hero {
    padding: 15px 0;
    overflow: hidden;
    position: relative;

    & > * {
        position: relative;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(1, 31, 35, 0.4);
        background: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.4) 48%,rgba(0,0,0,0.4) 100%);
        content: '';
        z-index: 0;
    }
}

.heroImage {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // polyfill
    font-family: 'object-fit: cover;';
    z-index: -1;
}

.heroFloodWarningsStatusContainer {
    border-bottom: 1px solid #B9C1C6;

    .constrainingContainer{
        .floodWarningsStatusError {
            line-height: 25px;
            background-color: rgba(0, 0, 0, 0.5);
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}

@media @desktop {
    .heroFloodWarningsStatusContainer {
        padding-top: 17px;
        margin-bottom: -67px - 17px;
        position: relative;
        z-index: 2;
        border-bottom: none;

        & + .hero {
            padding-top: 67px + 17px;
        }
    }
}