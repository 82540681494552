﻿@import "_config";

.recreationHeading {
    padding: 15px 0 30px 0;
    text-align: center;

    .constrainingContainer {
        &:after {
            display: table;
            clear: both;
            content: '';
        }
    }
}

.recreationHeadingText {
    margin-bottom: 0;
}

.recreationHeadingText h2 {
    font-size: 21px;
    font-weight: bold;
    font-family: @bodyFontFamily;
}

.recreationHeadingButtons {
    display: table;
    table-layout: fixed;

    .recreationHeadingButtonContainer {
        display: table-cell;
        padding-left: 8px;

        &:first-child {
            padding-left: 0;
        }
    }

    .button {
        display: block;
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        line-height: 1;
        min-height: 8px + 8px + 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:first-child {
            margin-left: 0;
        }
    }
}

.recreation .whatsOn,
.recreation #featuredContent,
.exploreMoreSection {
    h2 {
        margin-bottom: 15px;
        font-weight: bold;
        @media @tablet {
            padding: 0 15px;
        }
    }
}

.recreation .whatsOn,
.recreation #featuredContent,
.exploreMoreSection,
.recreation .placesToVisitMap {
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 0;

    h2 {
        font-size: 22px;
        padding: 0;
    }

    .constrainingContainer {
        &:after {
            display: table;
            content: '';
            clear: both;
        }
    }

    .featured, .relatedLinksBox {
        margin-bottom: 8px;
        display: block;
    }
}

.recreation .placesToVisitMap {
    padding-bottom: 20px;
    background-color: @lightGrey;

    h2 {
        font-family: @bodyFontFamily;
    }

    .sideLink {
        float: right;
        color: @lightGreen;
        font-size: 19px;
        font-weight: 600;
    }

    @media @onlyMobile {
        .mapImage {
            padding-right: 5px;
        }

        .mapText {
            padding-left: 5px;

            h2 {
                margin-bottom: 10px;
            }
        }
    }

    @media @notDesktop {
        .mapImage {
            img {
                width: 100%;
            }
        }
    }

    iframe {
        width: 100%;
        height: 90vh;
        height: calc(~"100vh - 32px");
        max-height: 750px;
        margin-top: 30px;
    }
}

.recreation .whatsOn {
    .featured {
        background-color: @lightGrey;

        .tag {
            background-color: @darkGreen;
            color: white;
        }

        h3 {
            color: @darkGreen;
        }

        p {
            color: @darkGrey;
        }

        .date {
            color: white;
        }
    }
}

.exploreMoreSection {

    .featured {
        background-color: @lightGrey;

        & {
            color: @bodyTextColour;
        }

        h3 {
            color: @aquaOnLightGrey;
        }

        p {
            display: none;
            color: @darkGrey;
        }
    }

    .first, .second {
        .featured {
            background-color: @lightGreen;

            &, h3, p {
                color: white;
            }

            a:hover h3 {
                border-bottom-color: white;
            }
        }
    }

    .tag {
        display: none;
    }
}

@media @onlyMobile {
    .exploreMoreSection .featured {
        a {
            min-height: 88px;
        }
    }
}

@media @tablet {
    .recreation #featuredContent,
    .recreation .whatsOn,
    .exploreMoreSection,
    .recreation .placesToVisitMap {
        padding-top: 40px;
        padding-bottom: 60px - 30px; // margin-bottom on feature boxes is subtracted from 60px
        .featured, .relatedLinksBox {
            margin-bottom: 30px;
        }
    }

    .exploreMoreSection {
        padding-bottom: 0;
    }

    .recreation .placesToVisitMap {
        padding-bottom: 60px;
    }

    .recreation .placesToVisitMap h2 {
        font-size: 30px;
    }

    .exploreMoreSection .constrainingContainer .relatedLinksBox {
        min-height: 152px;
    }

    .exploreMoreSection .constrainingContainer > .column:nth-child(even) {
        clear: both;
    }

    .recreation .whatsOn .constrainingContainer > .column:nth-child(even) {
        clear: both;
    }

    .recreationHeading {
        padding: 30px 0;
    }

    .recreationHeading .constrainingContainer {
        padding: 0;
    }

    .recreationHeadingText h2 {
        padding-top: ((8px + 8px + 14px) - 21px) / 2; // ((button height) - font size / 2) = excess spacing on top (and bottom)
        line-height: 1;
        border-right: 6px solid @lightGrey;
        margin-bottom: 0;
        min-height: (8px + 8px + 14px); // button height
        text-align: left;
        padding-right: 15px;
    }
}

@media @desktop {
    .exploreMoreSection .featured p {
        display: block;
    }

    .recreationHeading {
        padding: 60px 0;
    }

    .recreationHeadingText h2 {
        padding-top: (54px - 30px) / 2; // min-height - font-size
        min-height: 54px;
        font-size: 30px;
    }

    .recreationHeadingButtons {
        padding-left: 0;

        .recreationHeadingButtonContainer {
            padding-left: 70px;

            &:first-child {
                padding-left: 70px;
            }
        }

        .button {
            min-height: 54px;
            font-size: 18px;
        }
    }

    .recreation .whatsOn .constrainingContainer > .column:nth-child(even) {
        clear: none;
    }
}
