﻿@import "_config";
@import "_button";

.siteFooter {
    background-color: @darkGrey;
    border-top: 4px solid @lightGreen;
    color: white;
    position: relative;
}

.siteFooterContact {
    padding: 0 0 30px 0;
    display: table;
    width: 100%;

    @media @tablet {
        padding: 30px 0;
    }
}

.siteFooterContactPanel {
    background-color: darken(@darkGrey, 5%);
    padding: 15px 8px;
    height: (49px + (15px * 2));
    margin: 0 -8px;

    @media @tablet {
        margin: 0;
        padding: 15px 15px;
        width: 50%;
        display: table-cell;
        vertical-align: middle;
    }
}

.siteFooterContactButtonContainer {
    vertical-align: top;
    text-align: center;
    width: 100%;
}

.siteFooterContactButton {
    .button;
    border: 1px solid;
    height: 49px;
    padding: 0 15px;
    line-height: 1;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media @tablet {
        max-width: 300px;
        margin: 0 auto;
    }
}

.siteFooterContactNumber {
    font-weight: bold;
    font-size: 33px;
    margin: 0 15px;
    display: table-cell;
    vertical-align: middle;
    padding-left: 30px;
    white-space: nowrap;
}

.siteFooterSocialLinks {
    width: 100%;

    @media @tablet {
        width: 50%;
        display: table-cell;
        padding-left: 30px;
        text-align: center;
    }

    @media @desktop {
        text-align: right;
    }

    padding-right: 0;
    vertical-align: middle;
    font-size: 0; // Fix for inline-block extra spacing
}

.siteFooterSocialIcon {
    background-color: @lightGrey;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    // image replacement
    font: 0/0 a;
    background-position: center center;
    background-size: 22px 22px;
    background-repeat: no-repeat;
    margin-left: (13px);

    &Facebook {
        background-image: url('/images/facebook-logo.svg');
        background-size: 23px 23px;
        margin-left: 0;

        @media @desktop {
            margin-left: 13px;
        }
    }

    &Twitter {
        background-image: url('/images/twitter-logo.svg');
        background-size: 21px 21px;
    }

    &Linkedin {
        background-image: url('/images/linkedin-logo.svg');
        background-size: 19px 19px;
    }

    &Instagram {
        background-image: url('/images/instagram-logo.svg');
        background-size: 19px 19px;
    }

    &Rss {
        background-image: url('/images/rss-icon.svg');
        background-size: 18px 18px;
    }
}

.siteFooterSocialHeading {
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    h2 {
        color: white;
        margin: 20px 0;
        font-size: 21px;

        @media @tablet {
            margin-top: 0;
            margin-bottom: 10px;
        }

        @media @desktop {
            margin: 20px 0;
        }
    }

    @media @desktop {
        margin-right: (30px - 13px);
        width: auto;
        margin: auto;
    }
}

.siteFooterLinks, .siteFooterCopyrightSection {
    border-top: 1px solid lighten(@darkGrey, 5%);
    padding: 20px 0 0 0;

    @media @tablet {
        padding: 20px 0;
    }
}

.siteFooterCopyrightSection {
    padding: 15px 0 10px;
}

.siteFooterLinks {
    font-size: 0; //inline-block spacing fix
    display: inline-block;

    @media @tablet {
        display: block;
    }
}

.siteFooterLink {
    @media @tablet {
        border-left: 1px solid lighten(@darkGrey, 5%);
        width: auto;
        float: initial;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        margin-bottom: 0;
    }

    margin-bottom: 20px;
    font-weight: normal;
    width: 50%;
    float: left;
    color: white;
    font-size: 16px;
    display: inline-block;

    &:first-child {
        border-left: none;

        @media @tablet {
            margin-left: -15px;
        }
    }
}

.siteFooterCopyrightSection {
    &:after {
        display: table;
        clear: both;
        content: ' ';
    }
}

.siteFooterLogo {
    display: none;
    float: left;
    height: 48px;
    width: 276px;

    @media @tablet {
        display: block;
    }
}

.siteFooterCopyright {
    line-height: 48px;

    @media @tablet {
        float: right;
    }
}