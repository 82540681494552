﻿@import "_button";
@import "_config";

@siteNavDesktopHeight: 76px;

@media @desktop {

    .siteNavLink {
        span {
            font-weight: bold;
            position: relative;
        }
    }

    .siteNavItem .siteNavLink, .siteNavItem .siteNavLinkButton {
        line-height: @siteNavDesktopHeight;
        text-align: center;
        height: @siteNavDesktopHeight;

        & > span {
            display: inline-block;
            vertical-align: middle;
            line-height: 1em;
        }

        & > span.button {
            margin-left: -15px;
            margin-right: -15px;
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }

    .navButton .siteNavItem {
        padding-left: 1em;
    }
}
