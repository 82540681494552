﻿@import "_config";

.splashImage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // object-fit-images polyfill definition
    font-family: 'object-fit: cover;';
    // responsive image fallback for no-JS
    background-size: cover;
    z-index: 0;
}

@media only screen and (max-height:160px) {
    .splashModal {
        max-height: 98%;
        overflow-y: auto !important;
        padding-top: 2px !important;
    }
}

.splashModalOverlayContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    transform-style: preserve-3d;
    text-align: center;
    padding: 0 15px;
    pointer-events: all;

    &.darkened {
        background: rgba(0,0,0,0.6);
    }

    &.shadowed {
        .splashModal {
            box-shadow: 0px 2px 9px 6px rgba(36, 36, 36, 0.43);
        }
    }

    .splashModal {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
    }
}

.splashModal {
    max-width: 520px;
    background-color: white;
    padding-top: 30px;

    .splashModalLogo {
        margin: 30px;
        margin-top: 0;
        display: block;        
        
        img {
            max-width:100%;
            max-height:100%;
        }
    }

    .splashModalLinks {
        padding: 30px 30px;
        background-color: @lightGrey;
        text-align: center;
        width: 100%;

        a {
            width: 100%;
            display: block;
            padding: 12px 15px;
            line-height: 1;
            font-size: 16px;

            &:first-child {
                margin-bottom: 15px;
            }
        }
    }
}

@media screen and (min-width: @mobileWidth) {
    .splashModal {
        .splashModalLinks {
            display: table;
            border-spacing: 30px 0; // spacing between buttons / padding on the outside
            border-collapse: separate;
            table-layout: fixed;
            display: table;
            padding: 50px 0;

            a {
                display: table-cell;
            }

            a:first-child {
                margin-bottom: unset;
            }
        }
    }
}

html.splash {
    &, body {
        height: 100%;
    }

    body {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
}